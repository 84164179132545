import React from 'react';
import PropTypes from 'prop-types';

const MenuProfileIcon = ({width, height}) => (
  <svg width={width} height={height} viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M25.2969 0.519531C18.6665 0.519531 12.3076 3.15339 7.61914 7.8418C2.93073 12.5302 0.296875 18.8891 0.296875 25.5195C0.296875 32.1499 2.93073 38.5089 7.61914 43.1973C12.3076 47.8857 18.6665 50.5195 25.2969 50.5195C31.9273 50.5195 38.2862 47.8857 42.9746 43.1973C47.663 38.5089 50.2969 32.1499 50.2969 25.5195C50.2969 18.8891 47.663 12.5302 42.9746 7.8418C38.2862 3.15339 31.9273 0.519531 25.2969 0.519531ZM25.2969 7.98926C27.4902 7.98926 29.5936 8.86021 31.1445 10.4111C32.6955 11.9621 33.5669 14.0664 33.5669 16.2598C33.5669 18.4531 32.6955 20.5565 31.1445 22.1074C29.5936 23.6583 27.4902 24.5293 25.2969 24.5293C24.2113 24.5293 23.1366 24.3151 22.1338 23.8994C21.131 23.4837 20.2198 22.8745 19.4526 22.1064C18.6855 21.3384 18.0771 20.4271 17.6626 19.4238C17.2481 18.4205 17.0358 17.3453 17.0371 16.2598C17.0371 14.0682 17.9068 11.9657 19.4556 10.415C21.0043 8.8644 23.1053 7.99191 25.2969 7.98926ZM25.2969 43.9893C20.9149 43.9924 16.6766 42.428 13.3472 39.5791C12.9605 39.2479 12.6503 38.8367 12.438 38.374C12.2256 37.9113 12.1162 37.4085 12.1172 36.8994C12.1185 35.8017 12.3361 34.7145 12.7583 33.7012C13.1805 32.6879 13.7986 31.768 14.5771 30.9941C15.3557 30.2202 16.2796 29.6075 17.2954 29.1914C18.3112 28.7753 19.3993 28.5641 20.4971 28.5693H30.127C31.2238 28.5641 32.311 28.776 33.3257 29.1924C34.3404 29.6088 35.2626 30.2209 36.0396 30.9951C36.8165 31.7693 37.4325 32.6899 37.8525 33.7031C38.2726 34.7163 38.4881 35.8026 38.4868 36.8994C38.4878 37.4085 38.3784 37.9113 38.166 38.374C37.9537 38.8367 37.6435 39.2479 37.2568 39.5791C33.9234 42.4267 29.681 43.9878 25.2969 43.9795V43.9893Z" fill="currentColor"/>
  </svg>
);

MenuProfileIcon.defaultProps = {
  width: 51,
  height: 51,
};

MenuProfileIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default React.memo(MenuProfileIcon);
