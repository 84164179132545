import { ReactComponent as StandardIcon } from '@images/Menu/standard.svg'
import { ReactComponent as PercentageIcon } from '@images/Menu/percentage.svg'
import { ReactComponent as PaymentDefermentIcon } from '@images/Menu/payment-deferment.svg'
import { ReactComponent as GettingFundsIcon } from '@images/Menu/getting-funds.svg'
import { ReactComponent as StepsSignupIcon } from '@images/Menu/steps-signup.svg'
import { ReactComponent as FrequencyQuestionsIcon } from '@images/Menu/frequency-questions.svg'
import { ReactComponent as SupportIcon } from '@images/Menu/support.svg'

import { IMenuItemProps } from './types'

export const MENU: IMenuItemProps[] = [
  {
    to: '/#creditline',
    title: 'Кредитная линия',
  },
  {
    title: 'Продукты',
    nestedMenuList: [
      {
        to: '/#standard',
        title: 'Стандарт',
        description: 'Платежи равными частями',
        icon: StandardIcon,
      },
      {
        to: '/#percentage',
        title: 'Только проценты',
        description: 'Бесконечное пользование',
        icon: PercentageIcon,
      },
      {
        to: '/#payment_deferment',
        title: 'До зарплаты',
        description: 'Средства на короткий срок',
        icon: PaymentDefermentIcon,
      },
    ],
  },
  {
    title: 'Действия',
    nestedMenuList: [
      {
        to: '/#gettingfunds',
        title: 'Получение и возврат',
        description: 'Куда получить и как вернуть',
        icon: GettingFundsIcon,
      },
      {
        to: '/#stepssignup',
        title: 'Этапы регистрации',
        description: 'Что нужно для пользования',
        icon: StepsSignupIcon,
      },
    ],
  },
  {
    title: 'Ответы на вопросы',
    nestedMenuList: [
      {
        to: '/#stepsupport',
        title: 'Часто задаваемые вопросы',
        description: 'Подробнее о всех деталях',
        icon: FrequencyQuestionsIcon,
      },
      {
        to: '/#feedback',
        title: 'Служба поддержки',
        description: 'Задать свой вопрос',
        icon: SupportIcon,
      },
    ],
  },
]
