import React from 'react';
import { cn } from '@bem-react/classname';
import { ReactComponent as LoadingIcon } from '@src/assets/images/LoadingIcon.svg';
import './Loading.scss';

export const componentClassName = cn('Loading');

const Loading = () => (
  <LoadingIcon className={componentClassName('Container')} height={16} />
  // <img className={componentClassName('Container')} height={16} src={LoadingIcon} alt="Загрузка" />
)

export default Loading;
