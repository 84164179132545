import React, { useState } from 'react';
import classnames from 'classnames';
import ScrollableAnchor from 'react-scrollable-anchor';
import { TELEGRAM_LINK, WHATSAPP_LINK, WHATSAPP_LINK_MOBILE, VIBER_LINK } from '@src/constants';
import ProjectNameIconWhite from '@src/assets/images/ProjectNameIconWhite.svg';
import TelegramIcon from '@src/assets/images/TelegramIcon.svg';
import EmailIcon from '@src/assets/images/emailIcon.svg';
import BankRussiaSmoothIcon from '@src/assets/images/Footer/BankRussiaSmoothIcon.svg';
import BankRussiaActiveIcon from '@src/assets/images/Footer/BankRussiaActiveIcon.svg';
import BuroSmoothIcon from '@src/assets/images/Footer/BuroSmoothIcon.svg';
import BuroActiveIcon from '@src/assets/images/Footer/BuroActiveIcon.svg';
import CloudPaymentsSmoothIcon from '@src/assets/images/Footer/CloudPaymentsSmoothIcon.svg';
import CloudPaymentsActiveIcon from '@src/assets/images/Footer/CloudPaymentsActiveIcon.svg';
import MirSmoothIcon from '@src/assets/images/Footer/MirSmoothIcon.svg';
import MirActiveIcon from '@src/assets/images/Footer/MirActiveIcon.svg';
import SaltedgeSmoothIcon from '@src/assets/images/Footer/SaltedgeSmoothIcon.svg';
import SaltedgeActiveIcon from '@src/assets/images/Footer/SaltedgeActiveIcon.svg';
import ScoristaSmoothIcon from '@src/assets/images/Footer/ScoristaSmoothIcon.svg';
import ScoristaActiveIcon from '@src/assets/images/Footer/ScoristaActiveIcon.svg';
import WhatsappIcon from '@src/assets/images/WhatsappIcon.svg';

import './Footer.scss';
import cookie from "js-cookie";

const Footer = () => {
  const [bankRussiaIconStatus, setBankRussiaIconStatus] = useState('smooth');
  const [buroIconStatus, setBuroIconStatus] = useState('smooth');
  const [cloudPaymentsIconStatus, setCloudPaymentsIconStatus] = useState('smooth');
  const [mirIconStatus, setMirIconStatus] = useState('smooth');
  const [saltedgeIconStatus, setSaltedgeIconStatus] = useState('smooth');
  const [scoristaIconStatus, setScoristaIconStatus] = useState('smooth');

  const openSite = () => {
    /* global jivo_api */
    jivo_api.open();
  }

  const today = new Date();
  const year = today.getFullYear()

  const clickHandler = (link) => {
    const paramsCookie = cookie.get('params')

    let params = ''

    if (paramsCookie) {
      params = paramsCookie + '&redirect=credista'
    }

    window.location.href =`https://budgett.ru/${link}${params}`
  };

  return (
    <footer className={classnames('component-footer')}>
      <ScrollableAnchor id="footer">
        <div className="Width-Wrapper">
          <div className="component-footer__header-top">
            <a href="/#promo">
              <img className="component-footer__header-top__img" src={ProjectNameIconWhite} alt="Credista" />
            </a>
            <div className="component-footer__header-top__item_links-block">
              <div className="component-footer__link" onClick={() => clickHandler('finance-documents')}>
                Финансовые документы
              </div>
              <div className="component-footer__link" onClick={() => clickHandler('control-documents')}>
                Контрольные документы
              </div>
              <div className="component-footer__link" onClick={() => clickHandler('sitemap')}>
                Карта сайта
              </div>
            </div>
          </div>
          <div className="component-footer__header-bottom">
            <div className="component-footer__header-bottom__item copyright">ООО МКК «Диджитал Мани», {year}</div>
            <div className="component-footer__header-bottom__item component-footer__header-bottom__item_social-block">
              <p className="component-footer__header-bottom__title">Чат с поддержкой:</p>
              <div className="Jivo-Block" onClick={openSite}>
                {window.innerWidth > 1299 ? 'Оператор Онлайн' : 'Онлайн'}
              </div>
              <a href={TELEGRAM_LINK} className="component-footer__social-link" target="_blank" rel="noreferrer">
                <img className="component-footer__social-link__img" src={TelegramIcon} alt="Telegram"/>
              </a>
              <a href="mailto:support@budgett.ru" className="component-footer__social-link" target="_blank"
                 rel="noreferrer">
                <img className="component-footer__social-link__img" src={EmailIcon} alt="Email"/>
              </a>
            </div>
          </div>
          <p className="mobile-copyright">ООО МКК «Диджитал Мани», {year}</p>
          <div className="component-footer__mobile-links-block">
            <div onClick={() => clickHandler('finance-documents')}
                 className="component-footer__link component-footer__mobile-links-block__item">
              Финансовые документы
            </div>
            <div onClick={() => clickHandler('control-documents')} className="component-footer__link component-footer__mobile-links-block__item">
              Контрольные документы
            </div>
            <div onClick={() => clickHandler('sitemap')} className="component-footer__link component-footer__mobile-links-block__item">
              Карта сайта
            </div>
          </div>
          <p className="component-footer__layerinfo">Общество с ограниченной ответственностью
            Микрокредитная компания “Диджитал Мани”
            , ИНН 5445030457, ОГРН 1195476084968
            , номер в Государственном реестре ЦБ 2004150009570
            , номер в едином реестре СРО 54 001155
            . Юридический адрес:
            633011, Новосибирская область, город Бердск, улица Островского, дом 55, офис 21.
            Все права защищены.</p>
          <div className="component-footer__partners-block">
            <div className="component-footer__partners-block__title">
              Наши партнеры:
            </div>
            <div className="component-footer__partners-block__content">
              <a
                href="http://cbr.ru"
                target="_blank"
                rel="noreferrer"
                className="component-footer__partners-block__partner-link component-footer__partners-block__partner-link_first"
                onMouseEnter={() => setBankRussiaIconStatus('active')}
                onMouseLeave={() => setBankRussiaIconStatus('smooth')}
              >
                <img src={BankRussiaActiveIcon} className={classnames('component-footer__partners-block__partner-link__img', { 'component-footer__partners-block__partner-link__img_visible': bankRussiaIconStatus === 'active' })} alt="Банк России" />
                <img src={BankRussiaSmoothIcon} className={classnames('component-footer__partners-block__partner-link__img', { 'component-footer__partners-block__partner-link__img_visible': bankRussiaIconStatus === 'smooth' })} alt="Банк России" />
              </a>
              <a
                href="https://cloudpayments.ru"
                target="_blank"
                rel="noreferrer"
                className="component-footer__partners-block__partner-link component-footer__partners-block__partner-link_second"
                onMouseEnter={() => setCloudPaymentsIconStatus('active')}
                onMouseLeave={() => setCloudPaymentsIconStatus('smooth')}
              >
                <img src={CloudPaymentsActiveIcon} className={classnames('component-footer__partners-block__partner-link__img', { 'component-footer__partners-block__partner-link__img_visible': cloudPaymentsIconStatus === 'active' })} alt="CloudPayments" />
                <img src={CloudPaymentsSmoothIcon} className={classnames('component-footer__partners-block__partner-link__img', { 'component-footer__partners-block__partner-link__img_visible': cloudPaymentsIconStatus === 'smooth' })} alt="CloudPayments" />
              </a>
              <a
                href="https://www.saltedge.com"
                target="_blank"
                rel="noreferrer"
                className="component-footer__partners-block__partner-link component-footer__partners-block__partner-link_third"
                onMouseEnter={() => setSaltedgeIconStatus('active')}
                onMouseLeave={() => setSaltedgeIconStatus('smooth')}
              >
                <img src={SaltedgeActiveIcon} className={classnames('component-footer__partners-block__partner-link__img' ,{ 'component-footer__partners-block__partner-link__img_visible': saltedgeIconStatus === 'active' })} alt="SALTEDGE" />
                <img src={SaltedgeSmoothIcon} className={classnames('component-footer__partners-block__partner-link__img' ,{ 'component-footer__partners-block__partner-link__img_visible': saltedgeIconStatus === 'smooth' })} alt="SALTEDGE" />
              </a>

              <a
                href="https://www.nbki.ru"
                target="_blank"
                rel="noreferrer"
                className="component-footer__partners-block__partner-link component-footer__partners-block__partner-link_fourth"
                onMouseEnter={() => setBuroIconStatus('active')}
                onMouseLeave={() => setBuroIconStatus('smooth')}
              >
                <img src={BuroActiveIcon} className={classnames('component-footer__partners-block__partner-link__img', { 'component-footer__partners-block__partner-link__img_visible': buroIconStatus === 'active' })} alt="Бюро" />
                <img src={BuroSmoothIcon} className={classnames('component-footer__partners-block__partner-link__img', { 'component-footer__partners-block__partner-link__img_visible': buroIconStatus === 'smooth' })} alt="Бюро" />
              </a>
              <a
                href="https://scorista.ru"
                target="_blank"
                rel="noreferrer"
                className="component-footer__partners-block__partner-link component-footer__partners-block__partner-link_fifth"
                onMouseEnter={() => setScoristaIconStatus('active')}
                onMouseLeave={() => setScoristaIconStatus('smooth')}
              >
                <img src={ScoristaActiveIcon} className={classnames('component-footer__partners-block__partner-link__img' ,{ 'component-footer__partners-block__partner-link__img_visible': scoristaIconStatus === 'active' })} alt="SCORISTA" />
                <img src={ScoristaSmoothIcon} className={classnames('component-footer__partners-block__partner-link__img' ,{ 'component-footer__partners-block__partner-link__img_visible': scoristaIconStatus === 'smooth' })} alt="SCORISTA" />
              </a>
              <a
                href="https://npmir.ru"
                target="_blank"
                rel="noreferrer"
                className="component-footer__partners-block__partner-link component-footer__partners-block__partner-link_sixth"
                onMouseEnter={() => setMirIconStatus('active')}
                onMouseLeave={() => setMirIconStatus('smooth')}
              >
                <img src={MirActiveIcon} className={classnames('component-footer__partners-block__partner-link__img', { 'component-footer__partners-block__partner-link__img_visible': mirIconStatus === 'active' })} alt="МИР" />
                <img src={MirSmoothIcon} className={classnames('component-footer__partners-block__partner-link__img' ,{ 'component-footer__partners-block__partner-link__img_visible': mirIconStatus === 'smooth' })} alt="МИР" />
              </a>
            </div>
          </div>
        </div>
      </ScrollableAnchor>
    </footer>
  )};

export default Footer;
