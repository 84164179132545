import React from 'react';
import PropTypes from 'prop-types';

const MenuPaymentsIcon = ({width, height}) => (
  <svg width={width} height={height} viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.10938 11.4609C7.55772 11.4609 7.02875 11.2426 6.63867 10.8525C6.2486 10.4625 6.0293 9.93251 6.0293 9.38086V2.09082C6.0293 1.53917 6.2486 1.01019 6.63867 0.620117C7.02875 0.230042 7.55772 0.0117188 8.10938 0.0117188C8.66103 0.0117188 9.19 0.230042 9.58008 0.620117C9.97015 1.01019 10.1895 1.53917 10.1895 2.09082V9.38086C10.1895 9.93251 9.97015 10.4625 9.58008 10.8525C9.19 11.2426 8.66103 11.4609 8.10938 11.4609Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M18.0095 11.4609C17.4579 11.4609 16.9289 11.2426 16.5388 10.8525C16.1487 10.4625 15.9297 9.93251 15.9297 9.38086V2.09082C15.9297 1.53917 16.1487 1.01019 16.5388 0.620117C16.9289 0.230042 17.4579 0.0117188 18.0095 0.0117188C18.5612 0.0117188 19.0904 0.230042 19.4805 0.620117C19.8705 1.01019 20.0896 1.53917 20.0896 2.09082V9.38086C20.0896 9.93251 19.8705 10.4625 19.4805 10.8525C19.0904 11.2426 18.5612 11.4609 18.0095 11.4609Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M27.8984 11.4609C27.3468 11.4609 26.8178 11.2426 26.4277 10.8525C26.0377 10.4625 25.8184 9.93251 25.8184 9.38086V2.09082C25.8184 1.53917 26.0377 1.01019 26.4277 0.620117C26.8178 0.230042 27.3468 0.0117188 27.8984 0.0117188C28.4501 0.0117188 28.9791 0.230042 29.3691 0.620117C29.7592 1.01019 29.9785 1.53917 29.9785 2.09082V9.38086C29.9785 9.93251 29.7592 10.4625 29.3691 10.8525C28.9791 11.2426 28.4501 11.4609 27.8984 11.4609Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M29.988 4.69141H5.98804C4.46835 4.69141 3.01086 5.29456 1.93628 6.36914C0.861696 7.44372 0.257812 8.90121 0.257812 10.4209V38.0215C0.257812 39.5412 0.861696 40.9987 1.93628 42.0732C3.01086 43.1478 4.46835 43.751 5.98804 43.751H21.3779L21.8379 41.1914C22.042 40.0348 22.5931 38.967 23.418 38.1309L35.688 25.8809V10.4209C35.688 8.90121 35.0844 7.44372 34.0098 6.36914C32.9352 5.29456 31.4777 4.69141 29.958 4.69141H29.988ZM16.988 14.5908C17.5389 14.5934 18.0663 14.8136 18.4558 15.2031C18.8453 15.5926 19.0652 16.1201 19.0679 16.6709C19.0652 17.2217 18.8453 17.7501 18.4558 18.1396C18.0663 18.5292 17.5389 18.7483 16.988 18.751H8.65796C8.10712 18.7483 7.57945 18.5292 7.18994 18.1396C6.80043 17.7501 6.58051 17.2217 6.57788 16.6709C6.58051 16.1201 6.80043 15.5926 7.18994 15.2031C7.57945 14.8136 8.10712 14.5934 8.65796 14.5908H16.988ZM23.238 27.0908H8.65796C8.10712 27.0882 7.57945 26.869 7.18994 26.4795C6.80043 26.09 6.58051 25.5626 6.57788 25.0117C6.58051 24.4609 6.80043 23.9325 7.18994 23.543C7.57945 23.1535 8.10712 22.9343 8.65796 22.9316H23.238C23.7889 22.9343 24.3163 23.1535 24.7058 23.543C25.0953 23.9325 25.3152 24.4609 25.3179 25.0117C25.3126 25.5643 25.0889 26.0916 24.6953 26.4795C24.3018 26.8674 23.7706 27.0837 23.218 27.0811L23.238 27.0908Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M26.3793 50.0004C25.9662 49.9988 25.5705 49.8334 25.2792 49.5404C25.1015 49.3622 24.9698 49.144 24.8949 48.9037C24.8201 48.6634 24.8043 48.4082 24.8493 48.1606L25.9494 41.9008C26.0045 41.5867 26.1545 41.2969 26.3793 41.0707L41.8493 25.601C43.7493 23.701 45.6193 24.2109 46.6393 25.2309L49.2194 27.811C49.8985 28.4865 50.283 29.4029 50.2892 30.3608V30.4203C50.283 31.3782 49.8985 32.2956 49.2194 32.9711L33.7494 48.4408C33.524 48.6667 33.2339 48.8167 32.9193 48.8705L26.6593 49.9711H26.3893L26.3793 50.0004Z" fill="currentColor"/>
  </svg>

);

MenuPaymentsIcon.defaultProps = {
  width: 50,
  height: 50,
};

MenuPaymentsIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default React.memo(MenuPaymentsIcon);
