import React, { FC, memo, useEffect, useState } from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import cn from 'classnames';
import { ReactComponent as FastImage } from '@images/Advantages/Advantage1.svg';
import { ReactComponent as SafeImage } from '@images/Advantages/Advantage2.svg';
import { ReactComponent as ComfortableImage } from '@images/Advantages/Advantage3.svg';
import { ReactComponent as ReliableImage } from '@images/Advantages/Advantage4.svg';
import { ReactComponent as RateIcon } from '@images/Advantages/Description1.svg';
import { ReactComponent as RateReductionIcon } from '@images/Advantages/Description2.svg';
import { ReactComponent as LimitIcon } from '@images/Advantages/Description3.svg';
import { ReactComponent as TermIcon } from '@images/Advantages/Description4.svg';
import { ReactComponent as RepaymentIcon } from '@images/Advantages/Description5.svg';
import { ReactComponent as DailyIcon } from '@images/Advantages/Explain1.svg';
import { ReactComponent as SafeIcon } from '@images/Advantages/Explain2.svg';
import { ReactComponent as ComfortableIcon } from '@images/Advantages/Explain3.svg';
import { ReactComponent as ReliableIcon } from '@images/Advantages/Explain4.svg';
import Button, { BUTTON_STATUSES } from '@components/Button';

import cookie from 'js-cookie';

import styles from './Advantages.module.scss';

export interface IAdvantage {
  title: string
  text: JSX.Element
  image: JSX.Element
  icon: JSX.Element
}

interface IFeature {
  icon: JSX.Element
  text: JSX.Element
}

declare global {
  interface Window {
    ym:any
  }
}

const advantages: IAdvantage[] = [
  {
    title: 'С нами быстро',
    text: (
        <>
          Мы доступны 24/7 из любой точки мира. Мы ценим время клиентов, поэтому
          обрабатываем заявки максимально оперативно.
        </>
    ),
    image: <FastImage className={styles.fastImage} />,
    icon: <DailyIcon className={styles.advantageIcon} />,
  },
  {
    title: 'С нами безопасно',
    text: (
        <>
          Ваши персональные данные зашифрованы и надёжно защищены. Даже у наших
          специалистов нет доступа к ним.
        </>
    ),
    image: <SafeImage className={styles.safeImage} />,
    icon: <SafeIcon className={styles.advantageIcon} />,
  },
  {
    title: 'С нами комфортно',
    text: (
        <>
          У нас нет навязанных страховок, дополнительных сборов и скрытых комиссий
          за перевод средств. Всё просто, прозрачно и честно.
        </>
    ),
    image: <ComfortableImage className={styles.comfortableImage} />,
    icon: <ComfortableIcon className={styles.advantageIcon} />,
  },
  {
    title: 'С нами надежно',
    text: (
        <>
          Мы — проверенная микрокредитная организация с{' '}
          <a
              className={styles.link}
              href="https://budgett.ru/pdf/%D0%A1%D0%B2%D0%B8%D0%B4%D0%B5%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D1%82%D0%B2%D0%BE%20%D0%A6%D0%91.pdf"
              target="_blank"
              rel="noreferrer"
          >
            лицензией ЦБ РФ
          </a>
          . Работаем открыто и легально.
        </>
    ),
    image: <ReliableImage className={styles.reliableImage} />,
    icon: <ReliableIcon className={styles.advantageIcon} />,
  },
];

const features: IFeature[] = [
  {
    icon: <RateIcon className={styles.rateIcon} />,
    text: <>Ставка 0,5% в день</>,
  },
  {
    icon: <RateReductionIcon className={styles.rateReductionIcon} />,
    text: <>Снижение ставки до 0,2% в день</>,
  },
  {
    icon: <LimitIcon className={styles.limitIcon} />,
    text: <>Лимит от 15&nbsp;000 до 300&nbsp;000 рублей</>,
  },
  {
    icon: <TermIcon className={styles.termIcon} />,
    text: <>Срок до 36 месяцев</>,
  },
  {
    icon: <RepaymentIcon className={styles.repaymentIcon} />,
    text: <>Удобные варианты погашения</>,
  },
];

const Advantages: FC = () => {

  const [activeAdvantage, setActiveAdvantage] = useState<number | null>(null);

  const lessThenDesktop = window.innerWidth <= 1300;

  useEffect(() => {
    const id = setTimeout(() => setActiveAdvantage(0));

    return () => {
      clearTimeout(id);
    };
  }, []);

  const handleButtonClick = () => {
    window.ym(93509059,'reachGoal','get_money');
    const paramsCookie = cookie.get('params')

    let params = ''

    if (paramsCookie) {
      params = `${paramsCookie  }&redirect=credista`
    }

    window.location.href =`https://budgett.ru/sign_up${params}`
  };

  const setNextAdvantage = () => {
    setActiveAdvantage((prev) => {
      if (typeof prev === 'number' && prev !== advantages.length - 1) {
        return prev + 1;
      }

      return 0;
    });
  };

  return (
      <ScrollableAnchor id="promo">
        <section className={styles.section}>
          <div className={styles.topContainer}>
            <div className={styles.topContainerLeftColumn}>
              <h2 className={styles.title}>
                Выдаем крупные <br /> суммы на долгий срок
              </h2>
              <p className={styles.caption}>
                Мы меняем представление о займах.
                <br/>
                CREDISTA — понятные и удобные онлайн-займы нового поколения.
                <br/>
                Получать их легко, а гасить — комфортно.
              </p>
              {!lessThenDesktop && (
                  /* @ts-ignore */
                  <Button
                      status={BUTTON_STATUSES.NEW_PINK}
                      onClick={handleButtonClick}
                  >
                    Получить деньги
                  </Button>
              )}
            </div>

            <div className={styles.imageContainer}>
              {
                advantages[
                    activeAdvantage === null || lessThenDesktop
                        ? 0
                        : activeAdvantage
                    ].image
              }
            </div>
          </div>

          <ul className={styles.advantages}>
            {advantages.map(({ title, text, icon }, index) => (
                <li
                    className={cn(styles.advantage, {
                      [styles.advantageActive]:
                      index === activeAdvantage && !lessThenDesktop,
                    })}
                    onTransitionEnd={setNextAdvantage}
                >
                  {icon}
                  <h4 className={styles.advantageTitle}>{title}</h4>
                  <p className={styles.advantageText}>{text}</p>
                </li>
            ))}
          </ul>

          {!lessThenDesktop && (
              <h3 className={styles.featuresTitle}>Особенности сервиса</h3>
          )}

          <ScrollableAnchor id="conditions">
            <ul className={styles.features}>
              {features.map(({ icon, text }) => (
                  <li className={styles.feature}>
                    <div className={styles.iconContainer}>{icon}</div>
                    <p className={styles.featureText}>{text}</p>
                  </li>
              ))}
            </ul>
          </ScrollableAnchor>
        </section>
      </ScrollableAnchor>
  );
};

export default memo(Advantages);
