import React from 'react';
import PropTypes from 'prop-types';

const MenuSupportIcon = ({width, height}) => (
  <svg width={width} height={height} viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M50 25C50 18.3696 47.3662 12.0107 42.6777 7.32227C37.9893 2.63386 31.6304 0 25 0C18.3696 0 12.0109 2.63386 7.32252 7.32227C2.63411 12.0107 1.27341e-05 18.3696 1.27341e-05 25C-0.00450667 29.4712 1.19402 33.8613 3.46998 37.71L0.10011 49.9102L12.3001 46.54C16.1487 48.816 20.5388 50.0153 25.01 50.0107C31.6404 50.0107 37.9993 47.3759 42.6878 42.6875C47.3762 37.9991 50.01 31.6412 50.01 25.0107L50 25ZM21.1602 19.1807H17.3201C17.3227 17.1473 18.1318 15.1976 19.5696 13.7598C21.0074 12.3219 22.9566 11.5134 24.99 11.5107C27.0234 11.5134 28.9728 12.3219 30.4107 13.7598C31.8485 15.1976 32.6575 17.1473 32.6602 19.1807C32.6594 20.245 32.4377 21.2972 32.009 22.2715C31.5804 23.2458 30.9543 24.1201 30.1702 24.8398L26.9102 27.8398V30.8398H23.0801V26.1602L27.5901 22.04C27.9853 21.6824 28.3008 21.2454 28.5161 20.7578C28.7315 20.2703 28.8419 19.743 28.8401 19.21C28.8401 18.1915 28.4355 17.2153 27.7153 16.4951C26.9952 15.775 26.0184 15.3701 25 15.3701C23.9816 15.3701 23.0048 15.775 22.2847 16.4951C21.5645 17.2153 21.1602 18.1915 21.1602 19.21V19.1807ZM23.0801 34.6504H26.9202V38.4902H23.0801V34.6504Z" fill="currentColor"/>
  </svg>
);

MenuSupportIcon.defaultProps = {
  width: 51,
  height: 51,
};

MenuSupportIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default React.memo(MenuSupportIcon);
