import React, { FC, memo } from 'react'

import MenuItem from './MenuItem'
import { MENU } from './constants';

import styles from './Menu.module.scss'

const Menu: FC = () => (
  <nav className={styles.container}>
    <ul className={styles.list}>
      {MENU.map((menuItemProps) => <MenuItem {...menuItemProps} />)}
    </ul>
  </nav>
)

export default memo(Menu);
