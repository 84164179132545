import { getSearchParams } from '@src/utils/searchParams';
import { deleteCookie, ICookiesOptions, setCookie } from '@src/utils/cookies';

export const COOKIES_LIFETIME = 90 * 60 * 60 * 24 * 1000 // 90 days

export enum AGENTS {
  LIKNOT= 'LIKNOT',
  LEADS_SU = 'LEADS_SU',
  LEAD_TARGET = 'LEAD_TARGET',
  LEAD_GID = 'LEAD_GID',
  BANKI_RU = 'BANKI_RU',
  GURU_LEADS = 'GURU_LEADS',
  MASTER_TARGET = 'MASTER_TARGET',
  SA_LEADS = 'SA_LEADS',
  LEADS_TECH = 'LEADS_TECH',
  CLICK_2_MONEY = 'CLICK_2_MONEY',
  UNICOM = 'UNICOM',
  SRAVNI = 'SRAVNI'
}

interface ISetTargetCookiesOptions {
  transactionParam: string
  affiliateParam?: string
  agentName: AGENTS | string
}

const setTargetCookies = (
  {
    transactionParam,
    affiliateParam,
      agentName
  }: ISetTargetCookiesOptions
) => {
  const transactionParamVal = getSearchParams(transactionParam ?? '')
  const affiliateParamVal = getSearchParams(affiliateParam ?? '')
  const cookieOptions: ICookiesOptions = {
    expires: new Date(Date.now() + COOKIES_LIFETIME),
    domain: window.location.hostname,
  }

  setCookie('AGENT', agentName, cookieOptions)

  if (transactionParamVal) {
    setCookie('TRANSACTION_ID', transactionParamVal, cookieOptions)
  }

  if (affiliateParamVal) {
    setCookie('AFFILIATE_VALUE', affiliateParamVal, cookieOptions)
  }
}

export const cleanTargetCookies = () => {
  [
    'sravniTransactionId',
    'sravniaffiliate',
    'leadstechTransactionId',
    'leadstechaffiliate',
    'masterTransactionId',
    'masteraffiliate',
    'saleAdsTransactionId',
    'saleffiliate',
    'uniTransactionId',
    'uniAffiliate',
    'moneyTransactionId',
    'moneyAffiliate',
    'lidsTransactionId',
    'lidsAffiliate',
    'clickidTarget',
    'wmTargetId',
    'utm_campaign',
    'pidGuru',
    'web_id',
    'webLeadGid',
    'banksTransactionId',
    'wmId',
    'wmLiknot',
      'agent'
  ].forEach((cookie) => deleteCookie(cookie, { domain: window.location.host }))
}

export const targetHandler = () => {
  const utmSource = getSearchParams('utm_source')
  const utmMedium = getSearchParams('utm_medium')
  const transactionId = getSearchParams('transaction_id')
  const clickId = getSearchParams('click_id')

  if (utmSource || utmMedium || transactionId || clickId) {
    cleanTargetCookies()
  }


  switch (utmSource) {
    case 'sravni':
      setTargetCookies({
        transactionParam: 'click_id',
        affiliateParam: 'wm_id',
        agentName: AGENTS.SRAVNI,
      })

      break

    case 'leadstech': {
      setTargetCookies({
        transactionParam: 'click',
        affiliateParam: 'wm',
        agentName: AGENTS.LEADS_TECH
      })

      break
    }


    case 'mastertarget':
      setTargetCookies({
        transactionParam: 'click_id',
        affiliateParam: 'webmaster_id',
        agentName: AGENTS.MASTER_TARGET
      })

      break

    case 'saleads':
      setTargetCookies({
        transactionParam: 'click',
        affiliateParam: 'wm',
        agentName: AGENTS.SA_LEADS
      })

      break

    case 'unicom24':
      setTargetCookies({
        transactionParam: 'click_id',
        affiliateParam: 'wm_id',
        agentName: AGENTS.UNICOM
      })

      break

    case 'click2money':
      setTargetCookies({
        transactionParam: 'click_id',
        affiliateParam: 'user_id',
        agentName: AGENTS.CLICK_2_MONEY
      })

      break

    case 'leads.su':
      setTargetCookies({
        transactionParam: 'transaction_id',
        affiliateParam: 'affiliate_id',
        agentName: AGENTS.LEADS_SU
      })

      break

    case 'Leadtarget':
      setTargetCookies({
        transactionParam: 'clickid',
        affiliateParam: 'wmid',
        agentName: AGENTS.LEAD_TARGET
      })

      break

    case 'guruleads':
      setTargetCookies({
        transactionParam: 'click_id',
        affiliateParam: 'clickidGuru',
        agentName: AGENTS.GURU_LEADS
      })

      break

    default:
      if (utmMedium === 'leadgid') {
        setTargetCookies({
          transactionParam: 'click_id',
          affiliateParam: 'clickidLeadGid',
          agentName: AGENTS.LEAD_GID
        })
      } else if (transactionId && utmSource === null) {
        setTargetCookies({
          transactionParam: 'transaction_id',
          agentName: AGENTS.BANKI_RU
        })
      } else if (clickId) {
        setTargetCookies({
          transactionParam: 'click_id',
          affiliateParam: 'clickIdLiknot',
          agentName: AGENTS.LIKNOT
        })
      }
  }
}
