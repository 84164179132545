import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import { getLayout } from '@src/layouts/ProfileLayout';
import { TELEGRAM_LINK, WHATSAPP_LINK, WHATSAPP_LINK_MOBILE, VIBER_LINK } from '@src/constants';
import { ReactComponent as PlusIcon } from '@src/assets/images/PlusIcon.svg';
import TelegramIcon from '@src/assets/images/TelegramIcon.svg';
import WhatsappIcon from '@src/assets/images/WhatsappIcon.svg';
import {useLocation} from 'react-router-dom';
import Collapsible from 'react-collapsible';

import 'moment/locale/ru';
import './Support.scss';

import Button from '../../components/Button';

const openSite = () => {
  /* global jivo_api */
  jivo_api.open();
}


const Question = ({ title, description, closeAll, setCloseAll }) => {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const [isOpen, setOpen] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
    }
  }, [ref]);

  useEffect(() => {
    if (closeAll) {
      setOpen(false);
    }
  }, [closeAll]);

  return (
    <div className={classnames('page-support__question', { 'page-support__question_is-open': isOpen })}>
      <div className={classnames('page-support__question__header')}>
        <div className={classnames('page-support__question__title')}>
          {title}
        </div>
        <div className={classnames('page-support__question__close', { 'page-support__question__close_is-open': isOpen })} onClick={() => {
          setCloseAll(true);
          setTimeout(() => {
            setOpen(!isOpen);
          }, 10);
        }}>
          <PlusIcon />
        </div>
      </div>
      <div className={classnames('page-support__question__content', { 'page-support__question__content_is-open': isOpen })} style={{ height: isOpen ? height : 0 }}>
        <div ref={ref} className={classnames('page-support__question__content__slider')}>
          {description}
        </div>
      </div>
    </div>
  );
}

export const SupportQuestions = () => {
  const [closeAll, setCloseAll] = useState(true);

  useEffect(() => {
    if (closeAll) {
      setCloseAll(false);
    }
  }, [closeAll]);

  return (
    <div className={classnames('page-support__content')}>
      <div className={classnames('page-support__content__left')}>
        <Question
          title="Что такое кредитная линия?"
          description="Кредитная линия — это лимит денежных средств, который вам доступен. Он зависит от вашей платёжеспособности и от того, насколько подробные данные о себе вы предоставили. В верхней части меню личного кабинета есть ползунок - он наглядно показывает, сколько денег вы можете получить прямо сейчас, сколько — в будущем, и какие ваши данные потребуются для этого. "
          closeAll={closeAll}
          setCloseAll={setCloseAll}
        />
        <Question
          title="От чего зависит моя кредитная линия?"
          description="Мы обращаем внимание в первую очередь на вашу кредитную историю. Также имеют значение данные из Пенсионного фонда России и то, сколько денег вы получаете и тратите ежемесячно. "
          closeAll={closeAll}
          setCloseAll={setCloseAll}
        />
        <Question
          title="Моя кредитная линия равна нулю. Как мне получить деньги?"
          description={`Если ваша кредитная линия равна нулю, то это может означать следующее:
            \n * ваша кредитная история требует улучшения;
            \n * вы уже достигли предельной кредитной нагрузки.
            \n По правилам Центрального банка России, мы не имеем права предоставить вам средства. Улучшите свою кредитную историю, вовремя погасите текущие платежи и возвращайтесь повторно через месяц.
            `}
          closeAll={closeAll}
          setCloseAll={setCloseAll}
        />
        <Question
          title="Что такое продукт «Стандарт»?"
          description={`Стандарт — это аналог классического банковского кредита. В мировой практике он также называется installment-loan. Вы гасите части долга и проценты за пользование равными частями — ежемесячно, в течение установленного срока. 
             \nМы отличаемся от банка тем, что выдаём заём онлайн. Весь процесс занимает около 15 минут. При этом у нас нет скрытых страховок, комиссий и иных навязанных услуг.
             \nРанее этот продукт назывался «Installment».
            `}
          closeAll={closeAll}
          setCloseAll={setCloseAll}
        />
        <Question
          title="Что такое продукт «Только проценты»?"
          description={`Вы платите только проценты за пользование займом и не оплачиваете никаких комиссий и страховок. Этот вариант удобен для тех, кто хотел бы продлевать действие кредитного продукта или оформлять заём заново. Вы можете пользоваться средствами столько, сколько вам удобно, и сами определять дату погашения основного долга. 
            \nРанее этот продукт назывался «Infinit».
            `}
          closeAll={closeAll}
          setCloseAll={setCloseAll}
        />
        <Question
          title="Что такое продукт «До зарплаты»?"
          description={`До зарплаты — это заём от 3 000 рублей, который выдаётся на один месяц под 1% в день. Он выручит, если зарплата ещё не скоро, а деньги нужны прямо сейчас.
            Рассрочка погашения долга не предусмотрена. Погасить заём и проценты за пользование необходимо единовременно, по истечению месяца со дня оформления договора.`}
          closeAll={closeAll}
          setCloseAll={setCloseAll}
        />
        <Question
          title="Какие скрытые комиссии, продукты или страховки заложены в моём договоре?"
          description="Мы выстраиваем с заёмщиками честные и прозрачные отношения. Поэтому договор не содержит никаких дополнительных условий и комиссий. Конечные условия договора видны вам сразу."
          closeAll={closeAll}
          setCloseAll={setCloseAll}
        />
        <Question
          title="Что такое open-banking?"
          description="Это система доступа к данным о ваших тратах за определенный период. Мы анализируем, на что вы тратите деньги, и в каком объёме. Исходя из этого мы определяем вашу надёжность как заёмщика. Данные полностью обезличены и передаются с использованием самых надёжных защитных протоколов. Чем больше данных вы предоставите, тем выше ваши шансы получить максимальный одобренный лимит средств. "
          closeAll={closeAll}
          setCloseAll={setCloseAll}
        />
      </div>
      <div className={classnames('page-support__content__right')}>
        <Question
          title="Для чего вам данные о моём доходе?"
          description="Данные о вашем доходе и ваша кредитная история позволяют нам рассчитать максимальный лимит, который мы можем вам предложить. Так пользование нашими средствами будет комфортным и необременительным для вашего бюджета."
          closeAll={closeAll}
          setCloseAll={setCloseAll}
        />
        <Question
          title="Каким образом CREDISTA обрабатывает мои персональные данные?"
          description="Мы обрабатываем любые ваши персональные данные только после вашего согласия. Все ваши данные надёжно защищены, они абсолютно обезличены для технических специалистов сервиса и используются исключительно для определения вашей надёжности как заёмщика. Вы можете редактировать персональные данные или удалить аккаунт в личном кабинете."
          closeAll={closeAll}
          setCloseAll={setCloseAll}
        />
        <Question
          title="Где я могу прочитать полный текст договора?"
          description="Ознакомиться с полным текстом действующего договора или сохранить договор к себе на устройство можно в разделе «Документы» в личном кабинете. Подробная информация о закрытых договорах находится в «Истории закрытых договоров» в разделе «Настройки»."
          closeAll={closeAll}
          setCloseAll={setCloseAll}
        />
        <Question
          title="Как платить по договору?"
          description="Гасить займы можно как в ручном, так и в автоматическом режиме. Досрочно внести платёж можно в разделе «Мои платежи» в личном кабинете, а также при просмотре деталей конкретного договора. Также мы можем автоматически списывать средства с вашей карты в указанную дату платежа. Просто держите необходимую сумму на карте."
          closeAll={closeAll}
          setCloseAll={setCloseAll}
        />
        <Question
          title="Как быстро и удобно получить деньги?"
          description="Скорость и удобство — это главные преимущества нашего сервиса.  Вы можете получить деньги на все виды банковских карт: как дебетовых, так и кредитных. В ближайшее время мы запустим и другие способы дистанционного получения средств: на счёт в банке и на электронные кошельки. Деньги поступают на вашу карту сразу после проверки ваших данных и после того, как вы выбрали подходящий продукт. "
          closeAll={closeAll}
          setCloseAll={setCloseAll}
        />
        <Question
          title="Не могу загрузить фото паспорта и селфи. Что делать?"
          description="Возможно, размер файлов слишком большой. Уменьшите их размер в пределах 5 Мб. Если это не помогло, попробуйте повторить попытку через несколько минут, или оставьте свой номер для обратной связи."
          closeAll={closeAll}
          setCloseAll={setCloseAll}
        />
        <Question
          title="Я хочу получить средства на ЮMoney или Qiwi-кошелёк. Почему эта функция недоступна?"
          description="Мы активно работаем над этим. Совсем скоро вы сможете получать деньги на электронные кошельки. "
          closeAll={closeAll}
          setCloseAll={setCloseAll}
        />
        <Question
          title="Я хочу удалить данные о себе, как мне это сделать?"
          description="Удалить аккаунт и все данные о себе можно в личном кабинете: раздел «Настройки» — «Удалить аккаунт». Функция доступна, если у вас нет действующих договоров с нашим сервисом."
          closeAll={closeAll}
          setCloseAll={setCloseAll}
        />

      </div>
    </div>
  )
}

const Support = () => {
  const location = useLocation();
  const [hideSignUpStepsMobile, setHideSignUpStepsMobile] = useState(false)

  useEffect(() => {
    switch (location.hash) {
      case `#stepssignup`:
        setHideSignUpStepsMobile(true);
        break;
      default:
        break;
    }

  }, [location.hash]);

  return (
    <div className={classnames('page-support')}>
      <div className="page-support__desktop">
        <div className={classnames('page-support__header')}>
          <h2 className={classnames('page-support__title')}>Часто задаваемые вопросы</h2>
        </div>
        <SupportQuestions />
      </div>
      <Collapsible
        classParentString="page-support-mobile"
        contentOuterClassName="page-support-mobile__content-outer"
        triggerTagName="div" triggerClassName="page-support-mobile__title-mobile"
        triggerOpenedClassName={classnames('page-support-mobile__title-mobile', 'page-support-mobile__title-mobile_opened')}
        open={hideSignUpStepsMobile}
        onClick={()=>setHideSignUpStepsMobile(!hideSignUpStepsMobile)}
        trigger={
          <>
            <div className="page-support-mobile__title-container">
              <h2>Часто задаваемые вопросы</h2>
              <svg className="page-support__title-mobile__icon" xmlns="http://www.w3.org/2000/svg" width="27" height="19" viewBox="0 0 27 19" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.578711 4.24576C0.368133 3.98338 0.208918 3.68044 0.111589 3.3541C0.0142589 3.02775 -0.0198511 2.68459 0.0110276 2.34444C0.0419063 2.00429 0.137285 1.67354 0.291625 1.37187C0.445966 1.0702 0.65551 0.803224 0.90959 0.5864C1.42303 0.153162 2.0807 -0.0529313 2.73915 0.0116228C3.3976 0.0761769 4.00443 0.40656 4.43085 0.931941L13.4992 12.2547L22.5675 0.931941C22.9939 0.40656 23.6024 0.0761769 24.2608 0.0116228C24.9193 -0.0529313 25.5753 0.153162 26.0888 0.5864C26.3429 0.803224 26.554 1.0702 26.7084 1.37187C26.8627 1.67354 26.9581 2.00429 26.989 2.34444C27.0199 2.68459 26.9857 3.02775 26.8884 3.3541C26.7911 3.68044 26.6319 3.98338 26.4213 4.24576L15.4601 18.0539L15.1276 18.3994C14.8752 18.6183 14.5836 18.7829 14.2696 18.884C13.9556 18.9852 13.6254 19.0206 13.2981 18.9885C12.9708 18.9565 12.6525 18.8573 12.3622 18.6969C12.0719 18.5365 11.815 18.3179 11.6064 18.0539L0.578711 4.24576Z" fill="#1C2A40"/>
              </svg>
            </div>
            <p className="page-support-mobile__title-description">Ответы на наиболее часто задаваемые вопросы, которые позволят узнать чуть больше о работе сервиса и условиях договора.</p>
          </>
        }>
        <SupportQuestions />
      </Collapsible>
      <div className={classnames('page-support__footer')}>
        <p className={classnames('page-support__footer__description')}>Если Вам нужна помощь, и Вы не нашли ответа выше - свяжитесь со службой поддержки по бесплатному телефону, или в предпочитаемом мессенджере.</p>
        <div className='page-support__block'>
          <a className="page-support__link" href='tel:88005559980'>
            <Button>
              8 (800) 555-99-80
            </Button>
          </a>
          <div className="page-support__Jivo-Block" onClick={openSite}>
            {window.innerWidth > 850 ? 'Оператор Онлайн' : 'Онлайн'}
          </div>
          <a href={TELEGRAM_LINK} className="page-support__social-link" target="_blank" rel="noreferrer">
            <img className="page-support__social-link__img" src={TelegramIcon} alt="Telegram" />
          </a>
          <a href={window.innerWidth <= 1080 ? WHATSAPP_LINK_MOBILE : WHATSAPP_LINK} className="page-support__social-link" target="_blank" rel="noreferrer">
            <img className="page-support__social-link__img" src={WhatsappIcon} alt="Whatsapp" />
          </a>
        </div>
      </div>
    </div>
  )
};

Support.getLayout = getLayout;

export default Support;
