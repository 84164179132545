import React from 'react';
import styles from './NotFoundPage.module.scss';
import { getLayout } from '../../layouts/MainLayout';
import img from '../../assets/images/404.svg'
import {useHistory} from 'react-router-dom';

const NotFoundPage = () => {

  const history = useHistory();

  const toHome = () => {
      history.push('/')
  }

  return (
    <div className={styles.pageWrapper}>
      <img src={img} alt='404' />
      <p className={styles.pageHeader}>Похоже, такой страницы не существует</p>
      <p className={styles.pageText}>Убедитесь в правильности ввода адреса страницы и повторите попытку, или вернитесь на главную и воспользуйтесь картой сайта.</p>
        <button onClick={toHome} className={styles.pageButton}>Вернуться назад</button>
    </div>
  );
};

NotFoundPage.getLayout = getLayout;

export default NotFoundPage;
