export const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM;
export const CLOUDPAYMENTS_STORE_ID = process.env.REACT_APP_CLOUDPAYMENTS_STORE_ID;
export const KEYCLOAK_CLIENT = process.env.REACT_APP_KEYCLOAK_CLIENT;

export const AUTH_SERVICE_HOST = process.env.REACT_APP_AUTH_SERVICE_API_HOST;
export const PAYMENTS_SERVICE_API_HOST = process.env.REACT_APP_PAYMENTS_SERVICE_API_HOST;
export const SALTEDGE_SERVICE_API_HOST = process.env.REACT_APP_SALTEDGE_SERVICE_API_HOST;
export const FILE_SERVICE_API_HOST = process.env.REACT_APP_FILE_SERVICE_API_HOST;
export const PROFILE_SERVICE_API_HOST = process.env.REACT_APP_PROFILE_SERVICE_API_HOST;
export const SMS_SERVICE_API_HOST = process.env.REACT_APP_SMS_SERVICE_API_HOST;
export const MAIL_SERVICE_API_HOST = process.env.REACT_APP_MAIL_SERVICE_API_HOST;
export const REPORT_SERVICE_API_HOST = process.env.REACT_APP_REPORT_SERVICE_API_HOST;
export const PRINT_SERVICE_API_HOST = process.env.REACT_APP_PRINT_SERVICE_API_HOST;
export const CORE_SERVICE_API_HOST = process.env.REACT_APP_CORE_SERVICE_API_HOST;
export const DADATA_SERVICE_API_HOST = process.env.REACT_APP_DADATA_SERVICE_API_HOST;

export const PAYMENTS_INCOMING_3D_SECURE_BACK_URL = process.env.REACT_APP_PAYMENTS_INCOMING_3D_SECURE_BACK_URL;
export const PAYMENTS_OUTGOING_3D_SECURE_BACK_URL = process.env.REACT_APP_PAYMENTS_OUTGOING_3D_SECURE_BACK_URL;
export const PAYMENTS_PUBLIC_KEY = process.env.REACT_APP_PAYMENTS_PUBLIC_KEY;

export const FRONT_HOST = process.env.REACT_APP_FRONT_HOST;

export const TELEGRAM_LINK = 'https://t.me/budgettrus';
export const WHATSAPP_LINK = 'https://web.whatsapp.com/send/?phone=79858720171';
export const WHATSAPP_LINK_MOBILE = 'https://wa.me/79858720171';
export const VIBER_LINK = 'https://invite.viber.com/?g2=AQBTxXumbSOnok4G%2FHPSbVPLfRzqDkhSpzqn5TdUqHNLfIQxvH5XlD2enz7RtHmE';

export const DADATA_API_KEY = 'd70e6aa67583f6e9d982eaa8b37330c46366a845';
export const DADATA_SECRET_KEY = '66ab0f7c10d6915c58517f4d8108d62f6c93183c';

export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATE_FORMAT_DOT_NOTATION = 'DD.MM.yy';

export const MAX_CREDIT_ANOUNT = 300000;
export const MAX_CREDIT_ANOUNT_BEFORE_SALARY = 15000;

export const PRODUCTS = {
  INSTALLMENT: {
    key: 'INSTALLMENT',
    title: 'Стандарт'
  },
  INFINIT: {
    key: 'INFINIT',
    title: 'Только проценты'
  },
  BEFORE_SALARY: {
    key: 'BEFORE_SALARY',
    title: 'До зарплаты'
  },
}

export const MODERATION_STATUSES = {
  ON_CHECK: 'ON_CHECK',
  VERIFIED: 'VERIFIED',
  VERIFICATION_ERROR: 'VERIFICATION_ERROR',
}

export const ACCOUNT_MODERATION_STATUSES = {
  REGISTERED: 'REGISTERED',
  DATA_CHECKING: 'DATA_CHECKING',
  FAILURE_LITTLE_DATA: 'FAILURE_LITTLE_DATA',
  DATA_MISMATCH: 'DATA_MISMATCH',
  VERIFIED: 'VERIFIED',
  LIMIT_REACHED: 'LIMIT_REACHED'
}

export const ADDRESS_TYPES = {
  REGISTRATION: 'REGISTRATION',
  ACTUAL: 'ACTUAL'
}

export const CODE_SEND_INTERVAL = 60;
export const CODE_CHECK_STATUSES = {
  MATCH: 'MATCH',
  NOT_MATCH: 'NOT_MATCH',
  MANY_REQUESTS: 'MANY_REQUESTS',
}
export const CODE_EVENT_TYPE = {
  DEFAULT: 'DEFAULT',
  REGISTRATION: 'REGISTRATION',
  NEW_LOAN: 'NEW_LOAN',
  DELETE_PROFILE: 'DELETE_PROFILE',
  REGISTRATION_END: 'REGISTRATION_END'
}

export const EMPLOYMENT_OPTIONS = {
  STAFF_MEMBER: { value: 'STAFF_MEMBER', label: 'Штатный сотрудник' },
  INDIVIDUAL_BUSINESSMAN: { value: 'INDIVIDUAL_BUSINESSMAN', label: 'Индивидуальный предприниматель' },
  BUSINESS_OWNER: { value: 'BUSINESS_OWNER', label: 'Собственник бизнеса' },
  SELF_EMPLOYED_FACE: { value: 'SELF_EMPLOYED_FACE', label: 'Самозанятое лицо' },
  PENSIONER: { value: 'PENSIONER', label: 'Пенсионер' },
  MILITARY_PENSIONER: { value: 'MILITARY_PENSIONER', label: 'Военный пенсионер' },
  STUDENT: { value: 'STUDENT', label: 'Студент' },
  NOT_OFFICIALLY_EMPLOYED: { value: 'NOT_OFFICIALLY_EMPLOYED', label: 'Официально не трудоустроен' },
  IN_MATERNITY_LEAVE: { value: 'IN_MATERNITY_LEAVE', label: 'В декретном отпуске' },
  OTHER: { value: 'OTHER', label: 'Другое' }
}

export const MESSAGE = {
  TELEGRAM: { value: 'TELEGRAM', label: 'Telegram' },
  WHATS_APP: { value: 'WHATS_APP', label: 'WhatsApp' },
  VIBER: { value: 'VIBER', label: 'Viber' },
}

export const SOCIAL_NETWORK = {
  VK: { value: 'VK', label: 'VK' },
  CLASSMATES: { value: 'CLASSMATES', label: 'Одноклассники' },
  INSTAGRAM: { value: 'INSTAGRAM', label: 'Instagram' },
}

export const WHO_BELONG = {
  HUSBAND: { value: 'HUSBAND', label: 'Муж' },
  WIFE: { value: 'WIFE', label: 'Жена' },
  CLOSE_RELATIVE: { value: 'CLOSE_RELATIVE', label: 'Близкий родственник' },
  CLOSE_FRIEND: { value: 'CLOSE_FRIEND', label: 'Близкий друг' },
}

export const SOURCE_INCOME_OPTIONS = {
  STAFF_MEMBER: { value: 'STAFF_MEMBER', label: 'Зарплата' },
  INDIVIDUAL_BUSINESSMAN: { value: 'INDIVIDUAL_BUSINESSMAN', label: 'Доход от предпринимательской деятельности' },
  BUSINESS_OWNER: { value: 'BUSINESS_OWNER', label: 'Дивиденды' },
  SELF_EMPLOYED_FACE: { value: 'SELF_EMPLOYED_FACE', label: 'Доход от деятельности' },
  PENSIONER: { value: 'PENSIONER', label: 'Пенсия' },
  MILITARY_PENSIONER: { value: 'MILITARY_PENSIONER', label: 'Военная пенсия' },
  STUDENT: { value: 'STUDENT', label: 'Стипендия' },
  NOT_OFFICIALLY_EMPLOYED: { value: 'NOT_OFFICIALLY_EMPLOYED', label: 'Отсутствие официального дохода' },
  IN_MATERNITY_LEAVE: { value: 'IN_MATERNITY_LEAVE', label: 'Декретные выплаты' },
  OTHER: { value: 'OTHER', label: 'Другое' }
}

export const PURPOSE_OPTIONS = {
  FOR_PERSONAL_NEEDS: { value: 'FOR_PERSONAL_NEEDS', label: 'Для личных нужд' },
  OTHER: { value: 'OTHER', label: 'Иное' },
  LOAN_REFINANCING: { value: 'LOAN_REFINANCING', label: 'Рефинансирование кредита/займа' },
  PURCHASE_OF_EQUIPMENT: { value: 'PURCHASE_OF_EQUIPMENT', label: 'Покупка техники' }
}

export const ADDITIONAL_INCOME_OPTIONS = {
  RENTING_OUT_OF_REAL_ESTATE: { value: 'RENTING_OUT_OF_REAL_ESTATE', label: 'Сдача в аренду недвижимости' },
  PART_TIME_WORK: { value: 'PART_TIME_WORK', label: 'Работа по совместительству' },
  BUSINESS_DIVIDENDS: { value: 'BUSINESS_DIVIDENDS', label: 'Дивиденды от бизнеса' },
  SPOUSES_INCOME: { value: 'SPOUSES_INCOME', label: 'Доход супруги(а)' },
  OTHER: { value: 'OTHER', label: 'Прочее' },
  NO_INCOME: { value: 'NO_INCOME', label: 'Отсутствует' }
}

// Additional data:
export const FAMILY_STATUS_OPTIONS = {
  MARRIED: { value: 'MARRIED', label: 'Женат' },
  SINGLE: { value: 'SINGLE', label: 'Холост' },
}

export const CHILDREN_NUMBER_OPTIONS = {
  NOT: { value: 'NOT', label: 'Нет' },
  ONE: { value: 'ONE', label: '1' },
  TWO: { value: 'TWO', label: '2' },
  THREE: { value: 'THREE', label: '3' },
  FOUR: { value: 'FOUR', label: '4' },
  MORE_THAT_FOUR: { value: 'MORE_THAT_FOUR', label: 'Более 4' },
}

export const ACCOMMODATION_OPTIONS = {
  OWN_APARTMENT: { value: 'OWN_APARTMENT', label: 'Собственная квартира' },
  OWN_HOUSE: { value: 'OWN_HOUSE', label: 'Собственный дом' },
  RENT_HOUSE: { value: 'RENT_HOUSE', label: 'Снимаю жилье' },
  LIVE_WITH_RELATIVES: { value: 'LIVE_WITH_RELATIVES', label: 'Живу у родственников' },
  OTHER: { value: 'OTHER', label: 'Другое' },
}

export const EDUCATION_OPTIONS = {
  SCIENCE_DEGREE: { value: 'SCIENCE_DEGREE', label: 'Научная степень' },
  SEVERAL_HIGHER: { value: 'SEVERAL_HIGHER', label: 'Несколько высших' },
  HIGHER: { value: 'HIGHER', label: 'Высшее' },
  INCOMPLETE_HIGHER: { value: 'INCOMPLETE_HIGHER', label: 'Незаконченное высшее' },
  SPECIALIZED_SECONDARY: { value: 'SPECIALIZED_SECONDARY', label: 'Среднее специальное' },
  AVERAGE: { value: 'AVERAGE', label: 'Среднее' },
  OTHER: { value: 'OTHER', label: 'Другое' },
}

export const OTHER_LOAN_OPTIONS = {
  YES: { value: 'YES', label: 'Да' },
  NO: { value: 'NO', label: 'Нет' },
}

export const TRANSPORT_TYPE_OPTIONS = {
  PERSONAL_CAR: { value: 'PERSONAL_CAR', label: 'Личный автомобиль' },
  MOTORCYCLE: { value: 'MOTORCYCLE', label: 'Мотоцикл' },
  PUBLIC_TRANSPORT: { value: 'PUBLIC_TRANSPORT', label: 'Общественный транспорт' },
  BIKE: { value: 'BIKE', label: 'Велосипед' },
  OTHER: { value: 'OTHER', label: 'Прочее' },
}

export const TRAVEL_COUNTRY_NUMBER_OPTIONS = {
  NOT: { value: 'NOT', label: 'Нет' },
  ONE: { value: 'ONE', label: '1' },
  TWO: { value: 'TWO', label: '2' },
  THREE: { value: 'THREE', label: '3' },
  FOUR: { value: 'FOUR', label: '4' },
  FIVE: { value: 'FIVE', label: '5' },
  MORE_THAT_FIVE: { value: 'MORE_THAT_FIVE', label: 'Более 5' },
}

export const PLAN_TRAVEL_OPTIONS = {
  YES: { value: 'YES', label: 'Да' },
  NO: { value: 'NO', label: 'Нет' },
}
