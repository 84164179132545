import React, { useCallback, useState } from 'react';
import {useHistory} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '@src/store/ducks/auth';
import LogoIcon from '@src/assets/images/LogoIcon.svg';
import Button, { BUTTON_STATUSES } from '@src/components/Button';
import Menu from '@components/Menu';
import './Header.scss';
import Modal from 'react-modal';

import MobileWrapper from '../../../../components/MobileWrapper/MobileWrapper';
import cookie from "js-cookie";


const Header = () => {
  const [showBottomBlock, setShowBottomBlock] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { isAuthenticated, accountModerationStatus } = useSelector(({ auth, profile }) => ({
    isAuthenticated: auth.isAuthenticated,
    accountModerationStatus: profile.accountModerationStatus,
  }));

  const customStyles = {
    overlay: {
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      zIndex: 10,
      backgroundColor: 'rgba(28, 42, 64, 0.4)',
      display: 'flex',
      alignItems: 'end',
    },
    content: {
      position: 'relative',
      inset: 'inherit',
      border: 'none',
      background: 'none',
      overflow: 'inherit',
      borderRadius: 0,
      outline: 'none',
      padding: 0,
      height: 'fit-content',
      width: '100%',
    }
  };

  const handleLogout = useCallback(async () => {
    dispatch(
      logout()
    );
  }, []);

  const backHandler = () => {
    history.push('/sign_up')
  }

  const showTopBlockHandler = () => {
    setShowBottomBlock(true);
    document.body.style.overflow = 'hidden';
  }

  const handleCloseTopBlock = () => {
    setShowBottomBlock(false);
    document.body.style.overflow = '';
  }

  const redirectBudgett = () => {
    window.ym(93509059,'reachGoal','click_lk')
    const paramsCookie = cookie.get('params')

    let params = ''

    if (paramsCookie) {
      params = paramsCookie + '&redirect=credista'
    }

    window.location.href =`https://budgett.ru/login${params}`
  }

  return (
    <header className="component-header">
      <div className="Width-Wrapper Content-Block">
        <a href="/#promo" className="logo">
          <img className="logo__main" src={LogoIcon} alt="Logo" />
        </a>
        <Menu />
        <Button
          onClick={() => redirectBudgett()}
          status={BUTTON_STATUSES.HEADER}
          >
          <div className="text">Личный кабинет</div>
        </Button>
      </div>
      <Modal
        isOpen={showBottomBlock}
        onRequestClose={handleCloseTopBlock}
        style={customStyles}
      >
        <MobileWrapper hide={handleCloseTopBlock}>
          <div className="buttons-block">
            <div className="buttons-container">
              <button type='button' className="Back-to-reg" onClick={backHandler}>Завершить регистрацию</button>
              <a href="#" className="logout-block" onClick={handleLogout} >
                <div className="text-button">Выйти</div>
              </a>
            </div>
            <div onClick={handleCloseTopBlock} className="close-bottom-block" />
          </div>
        </MobileWrapper>
      </Modal>
    </header>
  )
};

export default Header;
