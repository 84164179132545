import React, { useEffect } from 'react';
import { getLayout } from '@src/layouts/MainLayout';

import Advantages from './components/Advantages';
import CreditLine from './components/CreditLine';
import Products from './components/Products';
import GettingFunds from './components/GettingFunds';
import SignUpSteps from './components/SignUpSteps';
import Support from './components/Support';
import Feedback from './components/Feedback';
import { targetHandler } from './targetHelpers';
import cookie from "js-cookie";

const LandingPage = () => {

  function leadCraftInfo(lifeTime) {
    const url = new URL(window.location)
    const params = url.search
    const _leadCraftTime = new Date((lifeTime || 90 * 60 * 60 * 24 * 1000) + new Date);
    const _leadCraftCS = `params=${  params  }; path=/; expires=${  _leadCraftTime  };`;
    document.cookie = _leadCraftCS;
  }
  leadCraftInfo(90 * 60 * 60 * 24 * 1000);

  useEffect(() => {
      targetHandler()
  },[])

  async function onProfilePress () {
    const name = cookie.get('AGENT')
    const transactionId = cookie.get('TRANSACTION_ID')
    const webId = cookie.get('AFFILIATE_VALUE')

    if (name) {
      console.log('name', name)
    }
  }

  return(
    <>
      <Advantages />
      <CreditLine />
      <Products />
      <GettingFunds />
      <SignUpSteps />
      <Support />
      <Feedback />
    </>
  );
}

LandingPage.getLayout = getLayout;

export default LandingPage;