import React, { CSSProperties, FC, HTMLProps, useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { IRangeProps } from './types'
import {
  RANGE_HANDLE_SIZE,
  RANGE_HANDLE_SIZE_MOBILE,
  RANGE_SECTIONS_GAP,
} from './constants';
import styles from './Range.module.scss'

const Range: FC<IRangeProps & HTMLProps<HTMLDivElement>> = ({
  points,
  initialPointIndex,
  onPointChange,
  className,
  ...containerProps
}) => {
  const rangeRef = useRef<HTMLDivElement>(null)
  const handleRef = useRef<HTMLDivElement>(null)
  const popupRef = useRef<HTMLDivElement>(null)

  const [ point, setPoint ] = useState<number>(initialPointIndex)
  const [ x, setX ] = useState<number>(0)
  const [ popupStyle, setPopupStyle ] = useState<CSSProperties | undefined>(undefined)
  const [ hasXBeenUpdated, setHasXBeenUpdated ] = useState<boolean>(false)

  const isMobileWidth = window.innerWidth <= 720

  const handleSize =
    isMobileWidth ? RANGE_HANDLE_SIZE_MOBILE : RANGE_HANDLE_SIZE

  useEffect(() => {
    onPointChange(point)
  }, [onPointChange, point])

  const dragHandler = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (event.cancelable) {
        event.preventDefault()
      }

      let clientX: number

      if (event.type === 'mousemove') {
        clientX = (event as MouseEvent).clientX
      } else {
        clientX = (event as TouchEvent).touches[0].clientX
      }

      if (rangeRef.current && handleRef.current) {
        const rangeRect = rangeRef.current.getBoundingClientRect()
        const halfOfHandleRect =
          handleRef.current.getBoundingClientRect().width / 2

        if (clientX <= rangeRect.left + halfOfHandleRect) {
          setX(0)
        } else if (clientX >= rangeRect.right - halfOfHandleRect) {
          setX(rangeRect.right - rangeRect.left - halfOfHandleRect * 2)
        } else {
          setX(clientX - rangeRect.left - halfOfHandleRect)
        }
      }
    },
    []
  )

  useEffect(
    () => {
      if (rangeRef.current  && handleRef.current) {
        const rangeElements = Array.from(rangeRef.current.children).slice(2)
        const handleWidthHalf = handleRef.current.getBoundingClientRect().width / 2
        const rangeLeft = rangeRef.current.getBoundingClientRect().left
        const rangeRight = rangeRef.current.getBoundingClientRect().right

        let edge = 0 - handleWidthHalf
        for (let i = 0; i < rangeElements.length; ++i) {
          const nextSectionWidth =
            rangeElements[i].getBoundingClientRect().width

          if (x >= rangeRight - rangeLeft - handleWidthHalf * 2) {
            setPoint(points.length - 1)
          } else if (x >= edge) {
            setPoint(i)
            edge += nextSectionWidth + RANGE_SECTIONS_GAP
          } else {
            break
          }
        }
      }
    },
    [points, x],
  )

  const adjustHandlePosition = useCallback(() => {
    if (rangeRef.current  && handleRef.current) {
      const rangeElements = Array.from(rangeRef.current.children).slice(2)
      const halfOfHandleRect =
        handleRef.current.getBoundingClientRect().width / 2

      if (x > 0 && x < rangeRef.current.getBoundingClientRect().width - halfOfHandleRect * 2) {
        let edge = 0
        for (let i = 0; i <= rangeElements.length; ++i) {
          if (x + halfOfHandleRect >= edge) {
            edge += rangeElements[i].getBoundingClientRect().width + RANGE_SECTIONS_GAP
          } else {
            const left = rangeElements[i - 1].getBoundingClientRect().left - rangeRef.current.getBoundingClientRect().left
            setX(left - (i === 1 ? 0 : halfOfHandleRect))

            break
          }

        }
      }
    }
  }, [x])

  const stopDragging = useCallback(
    () => {
      document.body.removeEventListener('mousemove', dragHandler)
      document.body.removeEventListener('touchmove', dragHandler)

      document.body.removeEventListener('mouseup', stopDragging)
      document.body.removeEventListener('touchend', stopDragging)

      document.body.style.overflow = ''

      setHasXBeenUpdated(true)
    },
    [dragHandler],
  )

  useEffect(
    () => {
      if (hasXBeenUpdated) {
        adjustHandlePosition()
        setHasXBeenUpdated(false)
      }
    },
    [hasXBeenUpdated, adjustHandlePosition]
  )

  const touchStartHandle = () => {
    document.body.style.overflow = 'hidden'

    addStartDraggingListeners()
  }

  const addStartDraggingListeners = () => {
    document.body.addEventListener('mousemove', dragHandler)
    document.body.addEventListener('touchmove', dragHandler)

    document.body.addEventListener('mouseup', stopDragging)
    document.body.addEventListener('touchend', stopDragging)
  }

  const handlePointClick = useCallback(
    (pointNumber: number) => {
      if (rangeRef.current && handleRef.current) {
        const rangeElements = Array.from(rangeRef.current.children).slice(2)
        const halfOfHandleRect =
          handleRef.current.getBoundingClientRect().width / 2

        const x = rangeElements.reduce(
          (x, element, index) =>
            index <= pointNumber ?
              x + element.getBoundingClientRect().width + (RANGE_SECTIONS_GAP) : x,
          0 - handleSize / 2,
        )

        setX(
          pointNumber === points.length - 1 ?
            x - RANGE_SECTIONS_GAP - halfOfHandleRect : x
        )
      }
    },
    [points, handleSize],
  )

  useEffect(() => {
    document.addEventListener('mouseleave', stopDragging)

    return () => {
      document.removeEventListener('mouseleave', stopDragging)
      stopDragging()
    }
  }, [stopDragging])

  const getPopupLeftPosition = (): CSSProperties | undefined => {
    if (rangeRef.current && handleRef.current && popupRef.current) {
      const rangeRect = rangeRef.current.getBoundingClientRect()
      const handleRect = handleRef.current.getBoundingClientRect()
      const popupRect = popupRef.current.getBoundingClientRect()

      const halfOfPopupWidth = popupRect.width / 2
      const startToHandle =
        (handleRect.right - handleRect.width / 2) - rangeRect.left
      const endToHandle =
        rangeRect.right - (handleRect.right - handleRect.width / 2)

      if (startToHandle < halfOfPopupWidth) {
        return { left: 0 }
      }
      if (endToHandle < halfOfPopupWidth) {
        return { right: 0 }
      }

      return { left: startToHandle - halfOfPopupWidth }
    }
  }

  useEffect(() => {
    setPopupStyle(getPopupLeftPosition())
  }, [x])

  const getInnerRightPosition = (): number => {
    if (rangeRef.current && handleRef.current) {
      const currentBlockRect =
        rangeRef.current.children[point + 2].getBoundingClientRect()
      const currentBlockRightPosition = currentBlockRect.right
      const handleRect = handleRef.current.getBoundingClientRect()
      const handleCenterPosition = handleRect.left + handleRect.width / 2

      return currentBlockRightPosition - handleCenterPosition
    }

    return 0
  }

  return (
    <div
      className={cn(styles.container, className)}
      {...containerProps}
      onMouseUp={adjustHandlePosition}
      onTouchEnd={adjustHandlePosition}
    >
      <div className={styles.top}>
        <div
          className={cn(
            styles.edgePoint,
            { [styles.edgePoint_selected]: point === 0 },
          )}
        >
          {points[0]?.text || ''}
        </div>
        <div
          className={cn(
              styles.edgePoint,
              { [styles.edgePoint_selected]: point === points.length - 1 },
          )}
        >
          {points[points.length - 1]?.text || ''}
        </div>
      </div>
      <div
        className={styles.range}
        ref={rangeRef}
        style={{ gap: RANGE_SECTIONS_GAP }}
      >
        <div
          className={styles.handle}
          ref={handleRef}
          onMouseDown={addStartDraggingListeners}
          onTouchStart={touchStartHandle}
          style={{
            left: x,
            width: handleSize,
            height: handleSize,
          }}
        />
        <div
          className={cn(
            styles.handle_popup,
            { [styles.handle_popup_invisible]: [0, points.length - 1].includes(point) },
          )}
          ref={popupRef}
          style={popupStyle}
        >
          {points[point].text}
        </div>

        {points.map(({ widthRatio }, index) => index < points.length - 1 && (
          <div
            className={cn(
              styles.point,
              { [styles.point_selected]: index < point }
            )}
            style={{ width: `${(widthRatio || 1) * 100}%` }}
            onClick={() => handlePointClick(index)}
          >
            {index === point && (
              <div
                className={styles.pointInner}
                style={{ right: getInnerRightPosition() }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Range
