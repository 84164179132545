import React, { useState } from 'react';
import Input from '@src/components/Input';
import Button from '@src/components/Button';
import formatters from '@src/utils/formatters';
import ScrollableAnchor from 'react-scrollable-anchor';

import './Feedback.scss'
import { BUTTON_STATUSES } from '../../../../components/Button';
import {sendHelpPhone} from '../../../../api';

const Feedback = () => {
  const [phone, setPhone] = useState('');
  const [clickedCounter, setClickedCounter] = useState(0);

  const buttonState = () => {
    if(phone.length === 18 && clickedCounter < 1){
      return BUTTON_STATUSES.ACTIVED;
    }
    if(clickedCounter >= 1 || phone.length < 18){
      return BUTTON_STATUSES.DISABLED;
    }
  }

  const clickHandler = async () => {
    window.ym(93509059,'reachGoal','need_call');
    const dataPhone = formatters.phone.raw(phone)
    const { result } = await sendHelpPhone({ phone: dataPhone });

    if (result) {
      setPhone('');
      if(phone.length === 18){
        setClickedCounter(clickedCounter + 1);
        sessionStorage.setItem('counter', clickedCounter.toString());
      }
    }
  }

  return (
    <ScrollableAnchor id="feedback">
      <div  className="feedback">
        <div className="feedback__wrapper">
          <h2 className="feedback__title">Нужна помощь?</h2>
          <p className="feedback__text">Укажите свой номер телефона, и мы оперативно перезвоним вам. Мы работаем круглосуточно.</p>
          <div className='feedback__form'>
            <Input
              type="tel"
              value={formatters.phone.format(phone).replace(/^\+7 \(/, '')}
              name="phone"
              prefixValue="+7 ("
              placeholder="900) 000-00-00"
              maxLength={14}
              onChange={(e) => setPhone(`+7 (${formatters.phone.format(e.target.value).replace(/^\+7 \(/, '')}`)}
            />
            <Button
              onClick={clickedCounter < 1 && phone.length === 18 && clickHandler}
              disabled={clickedCounter > 1 && phone.length < 18}
              status={buttonState()}
            >
              Заказать звонок
            </Button>
          </div>
          <p className='feedback__description'>
            Отправляя заявку, вы соглашаетесь с условиями передачи <br/> персональных данных и принимаете <a href="https://budgett.ru/pdf/Политика_обработки_персональных_данных.pdf" target="_blank" rel="noreferrer">Политику конфиденциальности.</a>
          </p>
        </div>
      </div>

    </ScrollableAnchor>
  );
};

export default Feedback;
