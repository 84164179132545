import React, {useEffect, useRef, useState} from 'react';

const MobileWrapper = ({children, hide = () => {}}) => {
  const scrollableContainer = useRef(null);

  const [startY, setStartY] = useState(null);
  const [onDrag, setOnDrag] = useState(false);
  const [displacement, setDisplacement] = useState(0);

  useEffect(() => {
    setOnDrag(displacement > 0);
  }, [displacement])

  const onTouchMove = (event) => {
    if (scrollableContainer.current.scrollTop === 0) {
      const {clientY} = event.touches[0];

      // eslint-disable-next-line no-unused-expressions
      !startY && setStartY(clientY);

      const delta = clientY - (startY || clientY);
      setDisplacement(delta >= 0 ? delta : 0);
    }
  }

  const onTouchEnd = () => {
    if (displacement > 100) {
      hide();
    }
    setStartY(null);
    setDisplacement(0);
  }

  return (
    <div
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      style={{transform: `translateY(${displacement}px)`}}
      ref={scrollableContainer}
    >
      {children}
    </div>
  );
};

export default MobileWrapper;