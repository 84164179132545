import React, { useContext, useCallback, useEffect, useState } from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import classnames from 'classnames';
import lodash from 'lodash';
import { cn } from '@bem-react/classname';
import { useDispatch, useSelector } from 'react-redux';
import { ACCOUNT_MODERATION_STATUSES , MAX_CREDIT_ANOUNT, MAX_CREDIT_ANOUNT_BEFORE_SALARY } from '@src/constants';
import { logout } from '@src/store/ducks/auth';
import Loading from '@src/components/Loading';
import Button, { BUTTON_STATUSES } from '@src/components/Button';
import LogoFullIcon from '@src/assets/images/ProfileHeaderLogoFullIcon.svg';
import LogoMobileMenu from '@src/assets/images/LogoMobileMenu.svg';
import MenuAddedIcon from '@src/assets/images/MenuAddedIcon.svg';
import PowerOffIcon from '@src/assets/images/PowerOffIcon.svg';
import AddCircleIcon from '@src/assets/images/AddCircleIcon.svg';
import CreditLinesCheckingBackground from '@src/assets/images/CreditLinesCheckingBackground.svg';
import { ReactComponent as ArrowCircleUp } from '@src/assets/images/ArrowCircleUp.svg';

import { HelpOverlayContext } from '../HelpOverlay';
import './Header.scss';

export const componentClassName = cn('profile-header');

const Header = (show) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { helpScreen, SCREENS } = useContext(HelpOverlayContext);
  const isHelpSecondScreen = helpScreen === SCREENS.SECOND;
  const isHelpThirdScreen = helpScreen === SCREENS.THIRD;
  const isButtonLocked = useSelector(({ layout }) => layout.isNavigationLocked);

  const { isAuthenticated, accountModerationStatus, creditLine } = useSelector(({ auth, profile }) => ({
    isAuthenticated: auth.isAuthenticated,
    accountModerationStatus: profile.accountModerationStatus,
    creditLine: profile.creditLine
  }));
  const isStatusChecking =
    !isHelpSecondScreen &&
    !isHelpThirdScreen &&
    accountModerationStatus === ACCOUNT_MODERATION_STATUSES.DATA_CHECKING;

  const isHiddenLine =
    accountModerationStatus === ACCOUNT_MODERATION_STATUSES.REGISTERED ||
    accountModerationStatus === ACCOUNT_MODERATION_STATUSES.DATA_MISMATCH ||
    accountModerationStatus === ACCOUNT_MODERATION_STATUSES.FAILURE_LITTLE_DATA;

  const [sections, setSections] = useState(lodash.fill(new Array(20), false));
  const [userCreditLimit, setUserCreditLimit] = useState(0);
  const [usedLimit, setUsedLimit] = useState(0);
  const [isBeforeSalary, setIsBeforeSalary] = useState(false);

  useEffect(() => {
    if (isHelpSecondScreen || isHelpThirdScreen) {
      if (isHelpThirdScreen) {
        setUserCreditLimit(0);
        setUsedLimit(0);
      } else {
        setUserCreditLimit(90000);
        setUsedLimit(60000);
      }
    } else {
      setUserCreditLimit(creditLine.userCreditLimit);
      setUsedLimit(creditLine.usedLimit);
      setIsBeforeSalary(creditLine.isBeforeSalary)
    }

  }, [helpScreen, creditLine]);

  useEffect(() => {
    const oneSectionAmount = (isBeforeSalary ? MAX_CREDIT_ANOUNT_BEFORE_SALARY : MAX_CREDIT_ANOUNT) / sections.length;

    setSections(sections.map((_, index) => {
      const sectionIndex = index + 1;
      if (sectionIndex * oneSectionAmount <= usedLimit) {
        return 'second';
      }

      if (sectionIndex * oneSectionAmount <= userCreditLimit) {
        return 'first';
      }

      return false;
    }));
  }, [userCreditLimit, usedLimit])

  const handleLogout = useCallback(async () => {
    dispatch(
      logout()
    );
  }, []);

  const widthWindow = () => window.innerWidth <= 1080

  return (

    <>
      {!widthWindow() &&
        <header className={componentClassName({ isHelpSecondScreen, isHelpThirdScreen })}>
          {(isHelpSecondScreen || isHelpThirdScreen) && <div className={componentClassName('Overlay')} />}
          <div className="Content-Block">
            <NavLink to="/" className="logo">
              <img src={LogoFullIcon} alt="Logo" />
            </NavLink>

            {!isHiddenLine && (
            <div className={componentClassName('CreditLineContainer')}>
              <p className={componentClassName('CreditLineTitle', { isHelpSecondScreen, isHelpThirdScreen })}>Кредитная линия:</p>
              <div className={componentClassName('CreditLine')}>
                <div className={componentClassName('CreditLineSections', {
                    onChecking: isStatusChecking,
                    isHelpSecondScreen,
                    isHelpThirdScreen
                  })} style={{
                    'backgroundImage': isStatusChecking ? `url(${CreditLinesCheckingBackground})` : 'unset',
                  }}>
                  {!isStatusChecking && sections.map((section, index) =>
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index} className={componentClassName('CreditLineSection', { isHelpSecondScreen, isHelpThirdScreen, status: section })} />
                  )}
                  {isHelpSecondScreen && (
                  <>
                    <p className={componentClassName('CreditLineHelpMessage', { First: true })}>
                      <ArrowCircleUp />
                      Насыщенным розовым показано, сколько средств использовано. А светлым розовым - сколько ещё средств вам доступно.
                    </p>
                    <p className={componentClassName('CreditLineHelpMessage', { Second: true })}>
                      <ArrowCircleUp />
                      Максимально возможный лимит - 300 000 Р.
                    </p>
                  </>
                    )}
                  {isHelpThirdScreen && (
                  <p className={componentClassName('CreditLineHelpMessage', { Fourth: true })}>
                    <ArrowCircleUp />
                    Если кредитная линия пуста, значит, сервис не одобрил вам лимит.<br/>
                    Чтобы это исправить, попробуйте добавить больше данных о себе, либо попробовать заново через месяц, устранив негативные факторы в кредитной истории.
                  </p>
                    )}
                </div>
                {isStatusChecking ? (
                  <p className={componentClassName('CreditLineDescription', { isHelpSecondScreen, isHelpThirdScreen })}>для Вашей безопасности мы тщательно сверяем данные</p>
                  ) : (usedLimit === 0 && userCreditLimit === 0) ? (
                    <p className={componentClassName('CreditLineDescription', { isHelpSecondScreen, isHelpThirdScreen })}>одобренный лимит отсутствует</p>
                  ) : (
                    <p className={componentClassName('CreditLineDescription', { isHelpSecondScreen, isHelpThirdScreen })}>Использовано {usedLimit} Р из доступного лимита объемом {userCreditLimit} Р</p>
                  )}
              </div>
              {(isStatusChecking) ? (
                <Button
                  onClick={() => {}}
                  disabled
                  status={BUTTON_STATUSES.DISABLED}
                    >
                  <Loading />
                  Проверка
                </Button>
                ) : (usedLimit === 0 && userCreditLimit === 0) ? (
                  <Button
                    onClick={() => {}}
                    status={BUTTON_STATUSES.DEFAULT}
                    >
                    <img src={AddCircleIcon} alt="Увеличить" />
                    Получить
                    {isHelpThirdScreen && (
                      <p className={componentClassName('CreditLineHelpMessage', { Fifth: true })}>
                        <ArrowCircleUp />
                        Нажмите на кнопку «Получить» и проверьте корректность заполнения персональных данных.
                      </p>
                      )}
                  </Button>
                ) : (
                  <Button
                    onClick={() => isHelpSecondScreen || isButtonLocked ? () => {} : history.push('/profile/edit/saltedge')}
                    status={isButtonLocked ? BUTTON_STATUSES.DISABLED : BUTTON_STATUSES.ACTIVED}
                    >
                    <img src={AddCircleIcon} alt="Увеличить" />
                    Увеличить
                    {isHelpSecondScreen && (
                      <p className={componentClassName('CreditLineHelpMessage', { Third: true })}>
                        <ArrowCircleUp />
                        Вы можете увеличить объем доступных средств,
                        предоставив больше дополнительных данных о себе.
                      </p>
                      )}
                  </Button>
                )}
            </div>
          )}

            {isAuthenticated ? (
              <div className="auth-icons">
                {/* <a href="/profile/add-contract" className="profile-block"> */}
                {/*   <div className="Icon"> */}
                {/*     <img src={ProfileIcon} alt="Profile" /> */}
                {/*   </div> */}
                {/* </a> */}
                <a href="#" className="logout-block logout-block__top" onClick={handleLogout}>
                  <div className="Icon">
                    <img src={PowerOffIcon} alt="Logout" />
                  </div>
                  <div className="text">Выйти</div>
                </a>
              </div>
          ) : (
            <NavLink to="/login" className="logo">
              <div className="btn">Войти</div>
            </NavLink>
          )}
          </div>
        </header>
  }
      {widthWindow() &&
        <header className={classnames({
          'profile-header': true,
          'isOpen': show.show,
          'isHelpScreen': isHelpThirdScreen || isHelpSecondScreen,
        })}>
          {(isHelpSecondScreen || isHelpThirdScreen) && <div className={componentClassName('Overlay')} />}
          <div className="Content-Block">
            <div className="Content-Top">
              <div className="logo">
                <img src={LogoMobileMenu} alt="Logo" />
              </div>

              {isAuthenticated ? (
                <div className="auth-icons">
                  <a href="#" className="logout-block" onClick={handleLogout}>
                    <div className="Icon">
                      <img src={PowerOffIcon} alt="Logout" />
                    </div>
                    <div className="text">Выйти</div>
                  </a>
                </div>
      ) : (
        <NavLink to="/login" className="logo">
          <div className="btn">Войти</div>
        </NavLink>
      )}
            </div>
            {!isHiddenLine && (
            <div className={componentClassName('CreditLineContainer')}>
              <div className={componentClassName('CreditLine')}>
                <div className={componentClassName('CreditLineSections', {
              onChecking: isStatusChecking,
              isHelpSecondScreen,
              isHelpThirdScreen
            })} style={{
              'backgroundImage': isStatusChecking ? `url(${CreditLinesCheckingBackground})` : 'unset',
            }}>
                  {!isStatusChecking && sections.map((section) =>
                    <div className={componentClassName('CreditLineSection', { isHelpSecondScreen, isHelpThirdScreen, status: section })} />
              )}
                  {isHelpSecondScreen && (
                  <>
                    <p className={componentClassName('CreditLineHelpMessage', { First: true })}>
                      <ArrowCircleUp />
                      Насыщенным розовым показано, сколько средств использовано. А светлым розовым - сколько ещё средств вам доступно.
                    </p>
                    <p className={componentClassName('CreditLineHelpMessage', { Second: true })}>
                      <ArrowCircleUp />
                      Максимально возможный лимит - 300 000 Р.
                    </p>
                    <p className={componentClassName('CreditLineHelpMessage', { MobileTwo: true })}>
                      <ArrowCircleUp />
                      Вы можете увеличить объем доступных средств,
                      предоставив больше дополнительных данных о себе.
                    </p>
                  </>
              )}
                  {isHelpThirdScreen && (
                    <>
                    <p className={componentClassName('CreditLineHelpMessage', { Fourth: true })}>
                      <ArrowCircleUp />
                      Если кредитная линия пуста, значит, сервис не одобрил вам лимит.<br/>
                      Чтобы это исправить, попробуйте добавить больше данных о себе, либо попробовать заново через месяц, устранив негативные факторы в кредитной истории.
                    </p>
                    <p className={componentClassName('CreditLineHelpMessage', { MobileThree: true })}>
                    <ArrowCircleUp />
                    Нажмите на кнопку «Получить» и проверьте корректность заполнения персональных данных.
                    </p>
                    </>
                  )}
                </div>
              </div>
              {(isStatusChecking) ? (
                <Loading />
          ) : (usedLimit === 0 && userCreditLimit === 0) ? (
            <img src={AddCircleIcon} alt="Увеличить" />
          ) : (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <img src={MenuAddedIcon} alt="Увеличить" onClick={() => isHelpSecondScreen ? () => {} : history.push('/profile/edit/additional')} className='addedLimitBtn'/>
          )}
            </div>
    )}
            {isStatusChecking ? (
              <p className={componentClassName('CreditLineDescription', { isHelpSecondScreen, isHelpThirdScreen })}>для Вашей безопасности мы тщательно сверяем данные</p>
            ) : (usedLimit === 0 && userCreditLimit === 0) ? (
              <p className={componentClassName('CreditLineDescription', { isHelpSecondScreen, isHelpThirdScreen })}>одобренный лимит отсутствует</p>
            ) : (
              <p className={componentClassName('CreditLineDescription', { isHelpSecondScreen, isHelpThirdScreen })}>Использовано {usedLimit} Р из доступного лимита объемом {userCreditLimit} Р</p>
            )}
          </div>
        </header>
  }
    </>
  )
};

export default Header;
