import React, { forwardRef } from 'react';
import { cn } from '@bem-react/classname';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './Input.scss';
import ErrorIcon from '@src/assets/images/ErrorIcon.svg';
import ConfirmIcon from '@src/assets/images/ConfirmIcon.svg';

export const componentClassName = cn('Input');

const Input = forwardRef(({
  heightFull,
  type,
  name,
  placeholder,
  prefixValue,
  value,
  autocomplete,
  isError,
  showConfirm,
  disabled,
  width,
  maxLength,
  onChange,
  onClick,
  onBlur = () => {},
  RightInlineElement,
  LeftOutlineElement,
  RightOutlineElement,
  attributes
}, ref) => {
  const isConfirmed = !!value && !isError && !disabled;

return (
  <div className={componentClassName()}>
    {!!LeftOutlineElement && (
    <div className={classnames('Left-Outline-Element-Container', { 'Confirmed': isConfirmed })}>
      {LeftOutlineElement}
    </div>
      )}
    <label
      htmlFor={name}
      className={componentClassName('Inline', {
          error: isError,
          width, disabled,
          has_right_element_outline: !!RightOutlineElement,
          has_left_element_outline: !!LeftOutlineElement,
          heightFull
        })}
      onClick={onClick}
      onKeyDown={onClick}
      onBlur={onBlur}
      role="presentation"
      >
      {!!prefixValue && (
      <span className="prefix">{prefixValue}</span>
        )}
      <div className="Wrapper-Block">
        <input
          id={name}
          className="input"
          type={type}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          autoComplete={autocomplete}
          ref={ref}
          value={value}
          maxLength={maxLength}
          onChange={onChange}
          {...attributes}
        />
      </div>
      {!!RightInlineElement && (
        <div className="Right-Inline-Container">
          {RightInlineElement}
        </div>
      )}
      {isError && (
        <div className="Error-Container">
          <img src={ErrorIcon} alt="Ошибка" />
        </div>
      )}
      {!!showConfirm && isConfirmed && (
        <div className="Confirm-Container">
          <img src={ConfirmIcon} alt="Все верно" />
        </div>
      )}
    </label>
    {!!RightOutlineElement && (
    <div className={classnames('Right-Outline-Element-Container', { 'Confirmed': isConfirmed })}>
      {RightOutlineElement}
    </div>
      )}
  </div>
  )
});

Input.propTypes = {
  heightFull: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  prefixValue: PropTypes.string,
  autocomplete: PropTypes.string,
  value: PropTypes.string,
  isError: PropTypes.bool,
  showConfirm: PropTypes.bool,
  disabled: PropTypes.bool,
  width: PropTypes.number,
  maxLength: PropTypes.number,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  RightInlineElement: PropTypes.node,
  LeftOutlineElement: PropTypes.node,
  RightOutlineElement: PropTypes.node,
  attributes: PropTypes.object
};

Input.defaultProps = {
  heightFull: false,
  type: 'text',
  name: undefined,
  prefixValue: '',
  placeholder: '',
  autocomplete: 'off',
  isError: false,
  showConfirm: false,
  disabled: false,
  width: undefined,
  maxLength: 200,
  onChange: () => {},
  onClick: () => {},
  RightInlineElement: null,
  LeftOutlineElement: null,
  RightOutlineElement: null,
  attributes: {},
  value: '',
}

export default Input;
