import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import Footer from '@src/components/Footer';
import { PopupProvider } from '@src/components/Popup';

import Header from './components/Header';
import Subnav from './components/Subnav';
import './MainLayout.scss';

export const componentClassName = cn('Layout-Main');

export const MainLayout = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <PopupProvider>
      <Header />
      <Subnav />
      <div className={componentClassName('Content-Block')}>
        {children}
      </div>
      <Footer />
    </PopupProvider>
  )
};

export const getLayout = (page) => <MainLayout>{page}</MainLayout>;
