import React from 'react';
import PropTypes from 'prop-types';

const AddContractIcon = ({width, height}) => (
  <svg width={width} height={height} viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.62988 0.650391C12.1097 0.650391 14.4877 1.63521 16.2412 3.38867C17.9947 5.14214 18.9795 7.52023 18.9795 10C18.9795 12.4798 17.9947 14.8579 16.2412 16.6113C14.4877 18.3648 12.1097 19.3496 9.62988 19.3496C7.15011 19.3496 4.77202 18.3648 3.01855 16.6113C1.26509 14.8579 0.280273 12.4798 0.280273 10C0.280273 7.52023 1.26509 5.14214 3.01855 3.38867C4.77202 1.63521 7.15011 0.650391 9.62988 0.650391ZM10.3701 5.20996H8.87988V9.2998H5.00977V10.6904H8.87988V14.79H10.3701V10.6904H14.2598V9.2998H10.3701V5.20996Z" fill="currentColor"/>
  </svg>

);

AddContractIcon.defaultProps = {
  width: 19,
  height: 20,
};

AddContractIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default React.memo(AddContractIcon);
