import React, { FC } from 'react'
import cn from 'classnames'

import { IMenuSubitemProps } from './types'

import styles from './MenuSubitem.module.scss'

const MenuSubitem: FC<IMenuSubitemProps> = ({
  title,
  description,
  icon: Icon,
  to,
  onClick,
}) => {
  if (to) {
    return(
      <a className={styles.item} href={to} onClick={onClick}>
        <Icon className={styles.itemIcon} />
        <div className={styles.itemTextContainer}>
          <p className={styles.itemTitle}>{title}</p>
          <p className={styles.itemDescription}>{description}</p>
        </div>
      </a>
    )
  }

  if (onClick) {
    return (
      <button
        className={cn(styles.button, styles.item)}
        type="button"
        onClick={onClick}
      >
        <Icon className={styles.itemIcon} />
        <div className={styles.itemTextContainer}>
          <p className={styles.itemTitle}>{title}</p>
          <p className={styles.itemDescription}>{description}</p>
        </div>
      </button>
    )
  }

  return null
}

export default MenuSubitem
