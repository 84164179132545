import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import ScrollableAnchor from 'react-scrollable-anchor';
import { CSSTransition } from 'react-transition-group';
import Button, { BUTTON_STATUSES } from '@src/components/Button';
import RightArrowIcon from '@images/RightArrowIcon.svg';
import Standard from '@images/Products/Installment.svg';
import Percentage from '@images/Products/Infinit.svg';
import BeforeSalary from '@images/Products/BeforeSalary.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './Products.scss';
import cookie from "js-cookie";
import index from 'react-select';

const TYPES = {
  STANDARD: {
    anchor: 'standard',
    key: 'standard',
    title: 'Стандарт',
    description:
        'Вы погашаете заём ежемесячно, равными частями. Каждый платёж состоит из основного долга и процентов за пользование средствами. \n Срок договора — от 1 до 36 месяцев.',
    icon: Standard,
    explain:
        'Стандарт — это аналог классического банковского кредита. Размер ежемесячного платежа сохраняется на протяжении всего срока договора. Платёж состоит из частичной оплаты основного долга и оплаты процентов за текущий период. Договор вы можете заключить на срок от 1 до 36 месяцев по своему выбору.',
    pillars: [61, 61, 61, 61, 61, 61, 61, 61, 61],
  },
  PERCENTAGE: {
    anchor: 'percentage',
    key: 'percentage',
    title: 'Только проценты',
    description:
        'Вы ежемесячно оплачиваете проценты за пользование средствами, а затем погашаете основной долг. \n Срок договора —  от 1 до 36 месяцев с возможностью продления.',
    icon: Percentage,
    explain:
        'Этот продукт позволит вам на протяжении длительного срока оплачивать только проценты за пользование средствами. Основной долг вы погашаете в последнюю очередь. Срок договора —  от 1 до 36 месяцев. Но он может быть продлён по вашему желанию. Чтобы прекратить действие договора, нужно вернуть весь объём изначально полученных средств.',
    pillars: [14, 14, 14, 14, 14, 14, 14, 14, 92],
  },
  PAYMENT_DEFERMENT: {
    anchor: 'payment_deferment',
    key: 'payment-deferment',
    title: 'До зарплаты',
    description:
        'Вы единовременно погашаете заём и проценты за пользование средствами через месяц после оформления займа. \n Срок договора — 1 месяц.',
    icon: BeforeSalary,
    explain:
        'Срочно нужны деньги, а зарплата ещё не пришла? Небольшой краткосрочный заём выручит в экстренной ситуации. \n' +
        'Договор займа оформляется на 1 месяц, под 1% в день. По истечению этого срока необходимо единовременно погасить долг и проценты за пользование. \n' +
        '\n',
    pillars: [77, 0, 0, 0, 0, 0, 0, 0, 0],
  },
};

TYPES.STANDARD.leftButton = TYPES.PAYMENT_DEFERMENT;
TYPES.STANDARD.rightButton = TYPES.PERCENTAGE;
TYPES.PERCENTAGE.leftButton = TYPES.STANDARD;
TYPES.PERCENTAGE.rightButton = TYPES.PAYMENT_DEFERMENT;
TYPES.PAYMENT_DEFERMENT.leftButton = TYPES.PERCENTAGE;
TYPES.PAYMENT_DEFERMENT.rightButton = TYPES.STANDARD;

const sliderOptions = {
  dots: true,
  arrows: false,
  centerMode: true,
  infinite: false,
  centerPadding: `0vw`,
  slidesToShow: 1,
  variableWidth: true,
  speed: 400,
  initialSlide: 0,
};

const SliderItem = ({ product }) => {
  const [showDetails, setShowDetails] = useState(false);
  const location = useLocation();

  const clickHandler = (index) => {
    const paramsCookie = cookie.get('params')

    if (index === 0) {
      window.ym(93509059,'reachGoal','get_standart');
    } if( index === 1) {
      window.ym(93509059,'reachGoal','get_percent');
    } if (index === 2) {
      window.ym(93509059,'reachGoal','get_salary');
    }

    let params = ''

    if (paramsCookie) {
      params = paramsCookie + '&redirect=credista'
    }

    window.location.href =`https://budgett.ru/sign_up${params}`
  };

  useEffect(() => {
    if (location.hash === `#${product.anchor}`) {
      setShowDetails(true);
    } else {
      setShowDetails(false);
    }
  }, [location.hash]);

  return (
      <div
          className={classnames(
              'component-products__carousel__item',
              showDetails && 'component-products__carousel__item_opened'
          )}
      >
        <div className="component-products__carousel__item__short">
          <img
              className="component-products__carousel__item__short__icon"
              src={product.icon}
              alt="Icon"
          />
          <div
              className="component-products__carousel__item__short__title"
              role="presentation"
              onClick={() => showDetails && setShowDetails(false)}
          >
            {product.title}
            <svg
                className="component-products__carousel__item__short__title__icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 22 16"
                fill="none"
            >
              <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.2674 4.01837C21.6036 3.61245 21.7654 3.08975 21.717 2.56492C21.6686 2.04009 21.4138 1.55563 21.009 1.21807C20.6031 0.881886 20.0808 0.720433 19.5559 0.768855C19.0311 0.817277 18.5467 1.07127 18.2091 1.47605L11.0008 10.143L3.80928 1.47605C3.47173 1.07127 2.98727 0.817277 2.46244 0.768855C1.93761 0.720433 1.41491 0.881886 1.00899 1.21807C0.604205 1.55563 0.349809 2.04009 0.301387 2.56492C0.252965 3.08975 0.414824 3.61245 0.751013 4.01837L9.46765 14.518L9.73417 14.785C10.1401 15.1212 10.6628 15.2826 11.1876 15.2342C11.7124 15.1858 12.1965 14.931 12.5341 14.5262L21.2674 4.01837Z"
                  fill="#FEFEFE"
              />
            </svg>
          </div>
          <p className="component-products__carousel__item__short__text">
            {product.description}
          </p>
          <div
              role="presentation"
              className="component-products__carousel__item__short__button"
              onClick={() => setShowDetails(true)}
          >
            Подробнее
          </div>
        </div>
        <div className="component-products__carousel__item__details">
          <div className="component-products__content-block__item__graph-block component-products__carousel__item__details__graph-block">
            <div className="component-products__content-block__item__graph-block__content-block component-products__carousel__item__details__graph-block__content-block">
              <table
                  className={classnames(
                      'component-products__content-block__item__graph-block__content-block__table-content',
                      product.key
                  )}
              >
                <tr className="component-products__content-block__item__graph-block__content-block__table-content__with-border">
                  <td
                      className="
                  component-products__content-block__item__graph-block__content-block__table-content__with-border
                  component-products__content-block__item__graph-block__content-block__table-content__max-width
                "
                  >
                    <div
                        className="
                    component-products__content-block__item__graph-block__content-block__table-content__arrow-block
                    component-products__content-block__item__graph-block__content-block__table-content__arrow-block_top
                  "
                    >
                      <div
                          className="
                      component-products__content-block__item__graph-block__content-block__table-content__arrow-block__arrow
                      component-products__content-block__item__graph-block__content-block__table-content__arrow-block__arrow_top
                    "
                      />
                    </div>
                  </td>
                  <td className="component-products__content-block__item__graph-block__content-block__table-content__main-cell">
                    {
                      /* eslint-disable react/no-array-index-key */
                      product.pillars.map((pillar, index) => (
                          <div
                              key={`pillar_${index}`}
                              className={`
                        component-products__content-block__item__graph-block__content-block__table-content__main-cell__pillar
                        component-products__content-block__item__graph-block__content-block__table-content__main-cell__pillar_${pillar}
                      `}
                          />
                      ))
                      /* eslint-enable react/no-array-index-key */
                    }
                  </td>
                </tr>
                <tr>
                  <td
                      className="
                  component-products__content-block__item__graph-block__content-block__table-content__with-border
                  component-products__content-block__item__graph-block__content-block__table-content__max-width
                  component-products__content-block__item__graph-block__content-block__table-content__min-height
                "
                  >
                    <div />
                  </td>
                  <td>
                    <div
                        className="
                    component-products__content-block__item__graph-block__content-block__table-content__arrow-block
                    component-products__content-block__item__graph-block__content-block__table-content__arrow-block_right
                  "
                    >
                      <div
                          className="
                      component-products__content-block__item__graph-block__content-block__table-content__arrow-block__arrow
                      component-products__content-block__item__graph-block__content-block__table-content__arrow-block__arrow_right
                    "
                      />
                    </div>
                  </td>
                </tr>
                <tr
                    className="
                component-products__content-block__item__graph-block__content-block__table-content__tier
                component-products__content-block__item__graph-block__content-block__table-content__tier_1
              "
                />
                <tr
                    className="
                component-products__content-block__item__graph-block__content-block__table-content__tier
                component-products__content-block__item__graph-block__content-block__table-content__tier_2
              "
                />
                <tr
                    className="
                component-products__content-block__item__graph-block__content-block__table-content__tier
                component-products__content-block__item__graph-block__content-block__table-content__tier_3
              "
                />
                <tr
                    className="
                component-products__content-block__item__graph-block__content-block__table-content__tier
                component-products__content-block__item__graph-block__content-block__table-content__tier_4
              "
                />
                <tr
                    className="
                component-products__content-block__item__graph-block__content-block__table-content__tier
                component-products__content-block__item__graph-block__content-block__table-content__tier_5
              "
                />
                <tr
                    className="
                component-products__content-block__item__graph-block__content-block__table-content__tier
                component-products__content-block__item__graph-block__content-block__table-content__tier_6
              "
                />
              </table>
              <p className="component-products__carousel__item__details__text">
                {product.explain}
              </p>
              <Button
                  className="component-products__carousel__item__details__button"
                  onClick={() => clickHandler(index)}
                  status={BUTTON_STATUSES.ACTIVED}
              >
                Оформить
              </Button>
            </div>
          </div>
        </div>
      </div>
  );
};

const Products = () => {
  const location = useLocation();
  const [showArrow, setShowArrow] = useState(false);
  const [selectedContent, setSelectedContent] = useState(false);
  const [content, setContent] = useState(false);
  const sliderRef = useRef(null);

  const clickHandler = (index) => {
    const paramsCookie = cookie.get('params')

    if (index === 0) {
      window.ym(93509059,'reachGoal','get_standart');
    } if( index === 1) {
      window.ym(93509059,'reachGoal','get_percent');
    } if (index === 2) {
      window.ym(93509059,'reachGoal','get_salary');
    }

    let params = ''

    if (paramsCookie) {
      params = paramsCookie + '&redirect=credista'
    }

    window.location.href =`https://budgett.ru/sign_up${params}`
  };

  useEffect(() => {
    switch (location.hash) {
      case `#${TYPES.STANDARD.anchor}`:
        setSelectedContent(TYPES.STANDARD);
        setContent(TYPES.STANDARD);
        sliderRef.current.slickGoTo(0);
        break;
      case `#${TYPES.PERCENTAGE.anchor}`:
        setSelectedContent(TYPES.PERCENTAGE);
        setContent(TYPES.PERCENTAGE);
        sliderRef.current.slickGoTo(1);
        break;
      case `#${TYPES.PAYMENT_DEFERMENT.anchor}`:
        setSelectedContent(TYPES.PAYMENT_DEFERMENT);
        setContent(TYPES.PAYMENT_DEFERMENT);
        sliderRef.current.slickGoTo(2);
        break;
      default:
        break;
    }
  }, [location.hash]);

  useEffect(() => {
    if (!selectedContent) {
      setContent(false);
    }
  }, [selectedContent]);

  return (
      <>
        <ScrollableAnchor id={TYPES.STANDARD.anchor}>
          <div />
        </ScrollableAnchor>
        <ScrollableAnchor id={TYPES.PERCENTAGE.anchor}>
          <div />
        </ScrollableAnchor>
        <ScrollableAnchor id={TYPES.PAYMENT_DEFERMENT.anchor}>
          <div />
        </ScrollableAnchor>
        <section className="component-products">
          <h2 className="component-products__title">Продукты</h2>
          <h2 className="component-products__title__mobile">Наши продукты</h2>

          <div
              className={classnames(
                  'component-products__content-block',
                  selectedContent?.key
              )}
          >
            {Object.values(TYPES).map((product, index) => (
                <ScrollableAnchor id={product.anchor}>
                  <div className="component-products__content-block__item">
                    <div className="component-products__content-block__item__product-block">
                      <img
                          className="component-products__content-block__item__product-block__icon"
                          src={content?.icon || product.icon}
                          alt="Icon"
                      />
                      <div className="component-products__content-title-wrap">
                        <h3>{content?.title || product.title}</h3>
                        <p>{content?.description || product.description}</p>
                      </div>
                      <div className="component-products__content-block__item__product-block__button-block">
                        <Button
                            status={
                              selectedContent
                                  ? BUTTON_STATUSES.SMOOTHED
                                  : BUTTON_STATUSES.ACTIVED
                            }
                            onClick={() =>
                                setSelectedContent(!selectedContent ? product : false)
                            }
                            onMouseEnter={() => setShowArrow(product.key)}
                            onMouseLeave={() => setShowArrow(false)}
                        >
                          {selectedContent ? 'Назад' : 'Подробнее'}
                        </Button>
                        <CSSTransition
                            in={showArrow === product.key}
                            timeout={200}
                            classNames="Right-Arrow"
                        >
                          {(state) => (
                              <div className={`Right-Arrow Right-Arrow-${state}`}>
                                <img src={RightArrowIcon} alt="Icon" />
                              </div>
                          )}
                        </CSSTransition>
                      </div>
                    </div>
                    <CSSTransition
                        in={selectedContent?.key === product.key}
                        timeout={200}
                        classNames="component-products__content-block__item__graph-block"
                    >
                      {(state) => (
                          <div
                              className={`
                    component-products__content-block__item__graph-block
                    component-products__content-block__item__graph-block-${state}
                  `}
                          >
                            <div className="component-products__content-block__item__graph-block__content-block">
                              <table className="component-products__content-block__item__graph-block__content-block__table-content">
                                <tr className="component-products__content-block__item__graph-block__content-block__table-content__with-border">
                                  <td
                                      className="
                            component-products__content-block__item__graph-block__content-block__table-content__with-border
                            component-products__content-block__item__graph-block__content-block__table-content__max-width
                          "
                                  >
                                    <div
                                        className="
                              component-products__content-block__item__graph-block__content-block__table-content__arrow-block
                              component-products__content-block__item__graph-block__content-block__table-content__arrow-block_top
                            "
                                    >
                                      <div
                                          className="
                                component-products__content-block__item__graph-block__content-block__table-content__arrow-block__arrow
                                component-products__content-block__item__graph-block__content-block__table-content__arrow-block__arrow_top
                              "
                                      />
                                    </div>
                                  </td>
                                  <td className="component-products__content-block__item__graph-block__content-block__table-content__main-cell">
                                    {
                                      /* eslint-disable react/no-array-index-key */
                                      (
                                          content?.pillars || selectedContent?.pillars
                                      )?.map((pillar, index) => (
                                          <div
                                              key={`pillar_${index}`}
                                              className={`
                                  component-products__content-block__item__graph-block__content-block__table-content__main-cell__pillar
                                  component-products__content-block__item__graph-block__content-block__table-content__main-cell__pillar_${pillar}
                                `}
                                          />
                                      ))
                                      /* eslint-enable react/no-array-index-key */
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                      className="
                            component-products__content-block__item__graph-block__content-block__table-content__with-border
                            component-products__content-block__item__graph-block__content-block__table-content__max-width
                            component-products__content-block__item__graph-block__content-block__table-content__min-height
                          "
                                  >
                                    <div />
                                  </td>
                                  <td>
                                    <div
                                        className="
                              component-products__content-block__item__graph-block__content-block__table-content__arrow-block
                              component-products__content-block__item__graph-block__content-block__table-content__arrow-block_right
                            "
                                    >
                                      <div
                                          className="
                                component-products__content-block__item__graph-block__content-block__table-content__arrow-block__arrow
                                component-products__content-block__item__graph-block__content-block__table-content__arrow-block__arrow_right
                              "
                                      />
                                    </div>
                                  </td>
                                </tr>
                                <tr
                                    className="
                          component-products__content-block__item__graph-block__content-block__table-content__tier
                          component-products__content-block__item__graph-block__content-block__table-content__tier_1
                        "
                                />
                                <tr
                                    className="
                          component-products__content-block__item__graph-block__content-block__table-content__tier
                          component-products__content-block__item__graph-block__content-block__table-content__tier_2
                        "
                                />
                                <tr
                                    className="
                          component-products__content-block__item__graph-block__content-block__table-content__tier
                          component-products__content-block__item__graph-block__content-block__table-content__tier_3
                        "
                                />
                                <tr
                                    className="
                          component-products__content-block__item__graph-block__content-block__table-content__tier
                          component-products__content-block__item__graph-block__content-block__table-content__tier_4
                        "
                                />
                                <tr
                                    className="
                          component-products__content-block__item__graph-block__content-block__table-content__tier
                          component-products__content-block__item__graph-block__content-block__table-content__tier_5
                        "
                                />
                                <tr
                                    className="
                          component-products__content-block__item__graph-block__content-block__table-content__tier
                          component-products__content-block__item__graph-block__content-block__table-content__tier_6
                        "
                                />
                              </table>
                              <p
                                  className={classnames(
                                      'component-products__content-block__item__graph-block__content-block__text',
                                      {
                                        'component-products__content-block__item__graph-block__content-block__text_active':
                                            content?.explain || selectedContent?.explain,
                                      }
                                  )}
                              >
                                {content?.explain || selectedContent?.explain}
                              </p>
                              <div className="component-products__content-block__item__graph-block__content-block__buttons">
                                <Button
                                    onClick={() => clickHandler(index)}
                                    status={BUTTON_STATUSES.ACTIVED}
                                >
                                  Оформить
                                </Button>
                              </div>
                            </div>
                          </div>
                      )}
                    </CSSTransition>
                  </div>
                </ScrollableAnchor>
            ))}
          </div>

          <div
              className={classnames(
                  'component-products__content-block component-products__content-block_mobile'
              )}
          >
            <Slider
                ref={sliderRef}
                {...sliderOptions}
                className="component-products__carousel"
            >
              {Object.values(TYPES).map((product, index) => (
                  <SliderItem id={product} product={product} index={index} />
              ))}
            </Slider>
          </div>
        </section>
      </>
  );
};

export default Products;
