import { IPoint } from './types'

export const REQUIREMENTS = [
  'Пройдите регистрацию и получайте стартовый лимит сразу после оценки вашей кредитной истории',
  'Подтвердите свой доход через механизм Open Banking или выпиской с расчетного счета в банке. ',
  'Предоставьте максимум дополнительных данных о себе, заполнив все информационные поля в своем профиле. ',
  'Совершайте платежи по своим договорам в срок и наблюдайте как расчет ваш лимит.',
  'Оставайтесь нашим клиентом на протяжении года и получайте максимально возможный лимит.',
]

export const RANGE_POINTS: IPoint[] = [
  { text: '15 000 \u20bd', widthRatio: 0.5 },
  { text: '30 000 \u20bd', widthRatio: 0.5 },
  { text: '60 000 \u20bd', widthRatio: 1 },
  { text: '90 000 \u20bd', widthRatio: 1 },
  { text: '120 000 \u20bd', widthRatio: 1 },
  { text: '150 000 \u20bd', widthRatio: 1 },
  { text: '180 000 \u20bd', widthRatio: 1 },
  { text: '210 000 \u20bd', widthRatio: 1 },
  { text: '240 000 \u20bd', widthRatio: 1 },
  { text: '270 000 \u20bd', widthRatio: 1 },
  { text: '300 000 \u20bd', widthRatio: 1 },
]

export const POINT_LEVEL_MAP = [1, 1, 2, 3, 4, 4, 4, 4, 4, 4, 5]

export const RANGE_HANDLE_SIZE = 30
export const RANGE_HANDLE_SIZE_MOBILE = 14
export const RANGE_SECTIONS_GAP = 1
