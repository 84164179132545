import React, { useState } from 'react';
import { cn } from '@bem-react/classname';
import CloseIcon from '@src/assets/images/CloseIcon.svg';
import './Popup.scss';

export const componentClassName = cn('Pop-Up');

export const PopupContext = React.createContext(null);

export const PopupProvider = ({ children }) => {
  const [isShown, setShown] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState(null);

  return (
    <PopupContext.Provider value={{
      isShown,
      title,
      content,
      setShown,
      setTitle,
      setContent
    }}>
      {children}
      <div className={componentClassName({ visible: !!isShown })}>
        <div 
          className={componentClassName('Overlay')} 
          onClick={() => setShown(false)} 
        />
        <div className={componentClassName('Block')}>
          <div className="Header">
            <p className="Title">
              {title}
            </p>
            <div onClick={() => setShown(false)}>
              <img
                alt={title}
                className="Close-Icon" 
                src={CloseIcon}
              />
            </div>
          </div>
          <div className="Content">
            <div>
              {content}
            </div>
          </div>
        </div>
      </div>
    </PopupContext.Provider>
  )
};

export default PopupProvider;
