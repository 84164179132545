import React, { FC, memo } from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'
import VisaIcon from '@images/PaymentSystems/VisaIcon.svg'
import MirIcon from '@images/PaymentSystems/MirIcon.svg'
import MasterCardIcon from '@images/PaymentSystems/MasterCardIcon.svg'
import BankForwardIcon from '@images/PaymentSystems/BankForwardIcon.svg'
import MainIcon from '@images/PaymentSystems/MainIcon.svg'
import QRIcon from '@images/PaymentSystems/QR.svg'
import SBPIcon from '@images/PaymentSystems/SBP.svg'

import styles from './GettingFunds.module.scss'

const GettingFunds: FC = () => (
  <ScrollableAnchor id="gettingfunds">
    <section className={styles.section}>
      <h2 className={styles.title}>Доступные системы переводов</h2>
      <p className={styles.caption}>
        Выбирайте комфортный для вас способ получения и возврата средств.
      </p>

      <div className={styles.contentContainer}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <div className={styles.itemPic}>
              <img className={styles.itemPicVisa} src={VisaIcon} alt="Visa" />
            </div>
            <p className={styles.itemText}>Карты платежной системы Visa</p>
          </li>

          <li className={styles.listItem}>
            <div className={styles.itemPic}>
              <img className={styles.itemPicMasterCard} src={MasterCardIcon} alt="MasterCard" />
            </div>
            <p className={styles.itemText}>Карты платежной системы Mastercard</p>
          </li>

          <li className={styles.listItem}>
            <div className={styles.itemPic}>
              <img className={styles.itemPicMir} src={MirIcon} alt="Mir" />
            </div>
            <p className={styles.itemText}>Карты платежной системы МИР</p>
          </li>

          <li className={styles.listItem}>
            <div className={styles.itemPic}>
              <img className={styles.itemPicBankTransfer} src={BankForwardIcon} alt="BankForward" />
            </div>
            <p className={styles.itemText}>Перевод на банковский счет</p>
          </li>

          <li className={styles.listItem}>
            <div className={styles.itemPic}>
              <img className={styles.itemPicQR} src={QRIcon} alt="QR" />
            </div>
            <p className={styles.itemText}>Быстрые переводы через QR-код</p>
          </li>

          <li className={styles.listItem}>
            <div className={styles.itemPic}>
              <img className={styles.itemPicSBP} src={SBPIcon} alt="SBP" />
            </div>
            <p className={styles.itemText}>Система быстрых платежей</p>
          </li>
        </ul>

        <img className={styles.mainImage} src={MainIcon} alt="Icon" />
      </div>
    </section>
  </ScrollableAnchor>
);

export default memo(GettingFunds);
