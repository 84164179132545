import React, { FC } from 'react';
import { ReactComponent as Star } from '@images/star.svg'
import cn from 'classnames'

import { IRequirementsBarProps } from './types'
import styles from './RequirementsBar.module.scss'

const RequirementsBar: FC<IRequirementsBarProps> = ({ items, position }) => (
  <ul className={styles.list}>
    {items.map((text, index) => (
      <li className={cn(
        styles.item,
        { [styles.currentItem]: position === index },
      )}>
        <div className={styles.stars}>
          {Array(items.length).fill(null).map((_, ind) => (
            <Star
              className={cn(
                styles.star,
                { [styles.star_active]: index <= position },
                { [styles.star_filled]: index <= position && ind <= index },
              )}
            />
          ))}
        </div>
        <p
          className={cn(
            styles.text,
            { [styles.text_active]: index <= position },
          )}
        >
          {text}
        </p>
      </li>
    ))}
  </ul>
)

export default RequirementsBar
