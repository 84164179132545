import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import OneIcon from '@src/assets/images/SignUpSteps/OneIcon.svg';
import TwoIcon from '@src/assets/images/SignUpSteps/TwoIcon.svg';
import ThreeIcon from '@src/assets/images/SignUpSteps/ThreeIcon.svg';
import FourIcon from '@src/assets/images/SignUpSteps/FourIcon.svg';
import FiveIcon from '@src/assets/images/SignUpSteps/FiveIcon.svg';
import SixIcon from '@src/assets/images/SignUpSteps/SixIcon.svg';
import CursorIcon from '@src/assets/images/SignUpStepsMobile/cursor.svg';
import './SignUpStepsMobile.scss';
import Collapsible from 'react-collapsible';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useLocation } from 'react-router-dom';

const content = [{
  title: 'Телефон <br /> и почта',
  description: 'Укажите адрес своей электронной почты и номер телефона. Они станут вашим ключом для входа в систему.',
  icon: OneIcon
}, {
  title: 'Паспортные <br /> данные',
  description: 'Введите свои паспортные данные. Они нужны, чтобы мы лучше узнали вас. Ваши данные будут под защитой. Мы никуда не передадим их без вашего согласия.',
  icon: TwoIcon
}, {
  title: 'Адрес <br /> проживания',
  description: 'Укажите адрес постоянной и временной регистрации. <br />  Это позволит предложить вам лучшие условия обслуживания в нашем сервисе.',
  icon: ThreeIcon
}, {
  title: 'Селфи <br /> с паспортом',
  description: 'Напишите на листе бумаги дату, время и слово CREDISTA. Сделайте селфи с этим листком и паспортом. <br /> Так мы убедимся, что именно вы решили воспользоваться нашими услугами, а не мошенники от вашего имени.',
  icon: FourIcon
}, {
  title: 'Анализ <br /> бюджета',
  description: 'Укажите источники и уровень вашего дохода, а также данные о месте вашей работы. Расскажите, для каких целей вы бы хотели получить деньги. Нам важно, чтобы выплата займа была для вас комфортной и необременительной.',
  icon: FiveIcon
}, {
  title: 'Анализ <br /> транзакций',
  description: 'Если вы хотите сразу увеличить кредитный лимит, предоставьте данные для проверки ваших расходов.',
  icon: SixIcon
}];

const CardSlider = ({ currentSlide, goBack }) => {
  const sliderOptions = {
    dots: true,
    arrows: false,
    centerMode: true,
    infinite: false,
    centerPadding: `0vw`,
    slidesToShow: 1,
    variableWidth: true,
    speed: 400,
    touchThreshold: 30,
  };

  const ref = useRef();

  useEffect(() =>{
    if (ref.current) {
      ref.current.slickGoTo(currentSlide);
    }
  }, [ref]);

  return (
    <div className="sign-up-steps-mobile__carousel">
      <Slider { ...sliderOptions } initialSlide={currentSlide} ref={ref}>
        {content.map((item) => (
          <div className="component-sign-up-steps__content-block__item sign-up-steps-mobile__carousel__item" key={item.title}>
            <div className="component-sign-up-steps__content-block__item__header-block sign-up-steps-mobile__carousel__item__header">
              <img className="component-sign-up-steps__content-block__item__header-block__image sign-up-steps-mobile__carousel__item__image"
                src={item.icon}
                alt="Icon"
              />
              <h3 className="component-sign-up-steps__content-block__item__header-block__title sign-up-steps-mobile__carousel__item__title"
                dangerouslySetInnerHTML={{__html: item.title}}
              />
              <span className="sign-up-steps-mobile__carousel__item__close" onClick={goBack} />
            </div>
            <p className="component-sign-up-steps__content-block__item__text sign-up-steps-mobile__carousel__item__text" dangerouslySetInnerHTML={{__html: item.description}} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

const SignUpStepsMobile = () => {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(null);
  const [hideSignUpStepsMobile, setHideSignUpStepsMobile] = useState(false)

  useEffect(() => {
    switch (location.hash) {
      case `#stepssignup`:
        setHideSignUpStepsMobile(true);
        break;
      default:
        break;
    }

  }, [location.hash]);

  return (
    <Collapsible
      classParentString="sign-up-steps-mobile"
      contentOuterClassName="sign-up-steps-mobile__content-outer"
      triggerTagName="div" triggerClassName="page-support__title-mobile"
      triggerOpenedClassName={classnames('page-support__title-mobile', 'page-support__title-mobile_opened')}
      open={hideSignUpStepsMobile}
      onClick={()=>setHideSignUpStepsMobile(!hideSignUpStepsMobile)}
      trigger={
        <>
          <h2>Регистрация в CREDISTA</h2>
          <svg className="page-support__title-mobile__icon" xmlns="http://www.w3.org/2000/svg" width="27" height="19" viewBox="0 0 27 19" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.578711 4.24576C0.368133 3.98338 0.208918 3.68044 0.111589 3.3541C0.0142589 3.02775 -0.0198511 2.68459 0.0110276 2.34444C0.0419063 2.00429 0.137285 1.67354 0.291625 1.37187C0.445966 1.0702 0.65551 0.803224 0.90959 0.5864C1.42303 0.153162 2.0807 -0.0529313 2.73915 0.0116228C3.3976 0.0761769 4.00443 0.40656 4.43085 0.931941L13.4992 12.2547L22.5675 0.931941C22.9939 0.40656 23.6024 0.0761769 24.2608 0.0116228C24.9193 -0.0529313 25.5753 0.153162 26.0888 0.5864C26.3429 0.803224 26.554 1.0702 26.7084 1.37187C26.8627 1.67354 26.9581 2.00429 26.989 2.34444C27.0199 2.68459 26.9857 3.02775 26.8884 3.3541C26.7911 3.68044 26.6319 3.98338 26.4213 4.24576L15.4601 18.0539L15.1276 18.3994C14.8752 18.6183 14.5836 18.7829 14.2696 18.884C13.9556 18.9852 13.6254 19.0206 13.2981 18.9885C12.9708 18.9565 12.6525 18.8573 12.3622 18.6969C12.0719 18.5365 11.815 18.3179 11.6064 18.0539L0.578711 4.24576Z" fill="#1C2A40"/>
          </svg>
        </>
      }>
      <div className="page-support__question__content__slider page-support__question__content__slider_intro sign-up-steps-mobile__intro">
        Чтобы воспользоваться нашими продуктами,<br/>  создайте личный кабинет. <br/> Это займёт всего несколько минут.
      </div>
      <div className="sign-up-steps-mobile__content">
        {selectedTab === null ? (
          <div className="sign-up-steps-mobile__tabs">
            <div className="sign-up-steps-mobile__tabs__container">
              {content.map((item, index) => (
                <div className="sign-up-steps-mobile__tabs__item"
                  dangerouslySetInnerHTML={{__html: item.title}}
                  onClick={() => setSelectedTab(index)}
                />
              ))}
            </div>
            <div className="sign-up-steps-mobile__tabs__hint">
              <img className="sign-up-steps-mobile__tabs__hint__image" src={CursorIcon} alt="" />
              <div className="sign-up-steps-mobile__tabs__hint__text">
                Нажмите на плитку, чтобы узнать подробности
              </div>
            </div>
          </div>
        ) : (
          <CardSlider currentSlide={selectedTab} goBack={() => setSelectedTab(null)} />
        )}
      </div>
    </Collapsible>
  );
}

export default SignUpStepsMobile;
