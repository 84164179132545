export const phone = {
  raw: (value) => `+${  String(value).replace(/\D/g, '')}`,
  format: (value) => {
    let cleanValue = String(value).replace(/^\+8/, '').replace(/^\+7/, '').replace(/\D/g, '');

    // In case of autofill from browser storage.
    if (cleanValue.length === 11) {
      cleanValue = cleanValue.slice(1);
    }

    const groups = cleanValue.match(/([0-9]{1,3})?([0-9]{1,3})?([0-9]{1,2})?([0-9]{1,2})?/);
    let result = '';

    if (groups[1]) {
      result += `+7 (${groups[1]}`;
    }

    if (groups[2]) {
      result += `) ${groups[2]}`;
    }

    if (groups[3]) {
      result += `-${groups[3]}`;
    }

    if (groups[4]) {
      result += `-${groups[4]}`;
    }

    return result;
  }
};

export const date = {
  raw: (value) => String(value).replace(/\D/g, ''),
  format: (value) => {
    const cleanValue = String(value).replace(/\D/g, '');
    const groups = cleanValue.match(/([0-9]{1,2})?([0-9]{1,2})?([0-9]{1,4})?/);

    let result = '';

    if (groups[1]) {
      result += `${groups[1]}`;
    }

    if (groups[2]) {
      result += `-${groups[2]}`;
    }

    if (groups[3]) {
      result += `-${groups[3]}`;
    }

    return result;
  }
};

export const digitalOnly = {
  raw: (value) => String(value),
  format: (value) => {
    const cleanValue = String(value).replace(/\D/g, '');

    return cleanValue;
  }
};

export const digitalAndDotOnly = {
  raw: (value) => String(value),
  format: (value) => {
    const splitted = String(value).split('.').slice(0, 2).map(v => v.replace(/\D/g, ''));

return splitted.join('.');
  }
};

export const lettersOnly = {
  raw: (value) => String(value),
  format: (value = '') => {
    const cleanValue = String(value).replace(/\d/g, '');

    return cleanValue;
  }
};

export const numberOnly = {
  raw: (value) => String(value),
  format: (value = '') => {
    const cleanValue = String(value).replace(/\D/g, '');

    return cleanValue;
  }
};

export const lettersRuOnly = {
  raw: (value) => String(value),
  format: (value = '') => {
    const cleanValue = String(value).replace(/\d/g, '')
      .replace(/[a-zA-Z]/g, '')
      .replace(/[!”@№#;$%^&?*+=]/g, '');

    return cleanValue;
  }
};

export const lettersEngOnly = {
  raw: (value) => String(value),
  format: (value = '') => {
    const cleanValue = String(value).replace(/\d/g, '')
      .replace(/[а-яА-Я]/g, '')
      .replace(/[!”@№#;$%^&?*+=]/g, '');

    return cleanValue;
  }
};

export const passwordReplace = {
  raw: (value) => String(value),
  format: (value = '') => {
    const cleanValue = String(value).replace(/[а-яА-Я]/g, '');

    return cleanValue;
  }
};

export const issuedBy = {
  raw: (value) => String(value),
  format: (value = '') => {
    const cleanValue = String(value).replace(/[a-zA-Z]/g, '');

    return cleanValue;
  }
};

export const departmentCode = {
  raw: (value) => String(value),
  format: (value) => {
    const cleanValue = String(value).replace(/\D/g, '');
    const groups = cleanValue.match(/([0-9]{1,3})?([0-9]{1,3})?/);

    let result = '';

    if (groups[1]) {
      result += `${groups[1]}`;
    }

    if (groups[2]) {
      result += `-${groups[2]}`;
    }

    return result;
  }
};

export const snills = {
  raw: (value) => String(value),
  format: (value) => {
    const cleanValue = String(value).replace(/\D/g, '');
    const groups = cleanValue.match(/([0-9]{1,3})?([0-9]{1,3})?([0-9]{1,3})?([0-9]{1,2})?/);

    let result = '';

    if (groups[1]) {
      result += `${groups[1]}`;
    }

    if (groups[2]) {
      result += `-${groups[2]}`;
    }

    if (groups[3]) {
      result += `-${groups[3]}`;
    }

    if (groups[4]) {
      result += `-${groups[4]}`;
    }

    return result;
  }
};

export const cardNumber = {
  raw: (value) => String(value),
  format: (value) => {
    const cleanValue = String(value).replace(/\D/g, '');
    const groups = cleanValue.match(/([0-9]{1,4})?([0-9]{1,4})?([0-9]{1,4})?([0-9]{1,4})?/);

    let result = '';

    if (groups[1]) {
      result += `${groups[1]}`;
    }

    if (groups[2]) {
      result += ` ${groups[2]}`;
    }

    if (groups[3]) {
      result += ` ${groups[3]}`;
    }

    if (groups[4]) {
      result += ` ${groups[4]}`;
    }

    return result;
  }
};

export const cardNumberHidden = {
  raw: (value) => String(value),
  format: (value) => {
    const cleanValue = String(value).replace(/[^0-9*]/g, '');
    const groups = cleanValue.match(/([0-9]{1,4})?([0-9*]{1,4})?([0-9*]{1,4})?([0-9*]{1,4})?/);

    let result = '';

    if (groups[1]) {
      result += `${groups[1]}`;
    }

    if (groups[2]) {
      result += ` ${groups[2].replace(/[0-9]/g, '*')}`;
    }

    if (groups[3]) {
      result += ` ${groups[3].replace(/[0-9]/g, '*')}`;
    }

    if (groups[4]) {
      result += ` ${groups[4].replace(/[0-9]/g, '*')}`;
    }

    return result;
  }
};

export default {
  phone,
  date,
  digitalOnly,
  digitalAndDotOnly,
  lettersOnly,
  numberOnly,
  lettersRuOnly,
  departmentCode,
  snills,
  cardNumber,
  cardNumberHidden,
  issuedBy,
  lettersEngOnly,
  passwordReplace
}
