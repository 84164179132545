import React, { FC } from 'react'

import { ReactComponent as ArrowIcon } from '@images/Menu/arrow.svg'

import MenuSubitem from './MenuSubitem'
import { IMenuItemProps } from './types'

import styles from './MenuItem.module.scss'

const MenuItem: FC<IMenuItemProps> = ({ title, to, nestedMenuList }) => {
  if (to) {
    return (
      <li className={styles.container}>
        <a className={styles.link} href={to}>{title}</a>
      </li>
    )
  }

  if (nestedMenuList) {
    return (
      <li className={styles.container}>
        <div className={styles.topContainer}>
          <p className={styles.title}>{title}</p>
          <ArrowIcon className={styles.arrowIcon} />
        </div>

        <ul className={styles.nestedMenuList}>
          {nestedMenuList.map((menuSubitemProps) => (
            <li>
              <MenuSubitem {...menuSubitemProps} />
            </li>
          ))}
        </ul>
      </li>
    )
  }

  return null
}

export default MenuItem
