import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button, { BUTTON_STATUSES } from '@src/components/Button';
import { setUserTrainingFlag } from '@src/api';
import { ReactComponent as ArrowLeftWhiteIcon } from '@src/assets/images/ArrowLeftWhiteIcon.svg';
import { ReactComponent as ArrowRightWhiteIcon } from '@src/assets/images/ArrowRightWhiteIcon.svg';
import IntroFirst from '@src/assets/images/IntroFirst.svg';
import className from 'classnames/bind';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@src/pages/SignUp/components/SliderStyle.scss'

import styles from './HelpOverlay.module.scss';

const cx = className.bind(styles);

export const SCREENS = {
  FIRST: 'First',
  SECOND: 'Second',
  THIRD: 'Third',
  FOURTH: 'Fourth',
  FIFTH: 'Fifth',
};

export const HelpOverlayContext = React.createContext(null);

export const HelpOverlay = ({ children }) => {
  const { training } = useSelector(({ profile }) => profile.flags);
  const [isShown, setShown] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(SCREENS.FIRST);

  const pointClassesFirst = cx({
    Point: true,
    actived: currentScreen === SCREENS.FIRST,
  });

  const pointClassesSecond = cx({
    Point: true,
    actived: currentScreen === SCREENS.SECOND,
  });

  const pointClassesThird = cx({
    Point: true,
    actived: currentScreen === SCREENS.THIRD,
  });

  const pointClassesFourth = cx({
    Point: true,
    actived: currentScreen === SCREENS.FOURTH,
  });

  const pointClassesFifth = cx({
    Point: true,
    actived: currentScreen === SCREENS.FIFTH,
  });

  const screensContainer = cx({
    ScreensContainer: true,
    ScreensContainerFirst: currentScreen === SCREENS.FIRST,
    ScreensContainerSecond: currentScreen === SCREENS.SECOND,
    ScreensContainerThird: currentScreen === SCREENS.THIRD,
    ScreensContainerFourth: currentScreen === SCREENS.FOURTH,
    ScreensContainerFifth: currentScreen === SCREENS.FIFTH,
  })

  useEffect(() => {
    if (!isShown) {
      setCurrentScreen(null);
    } else {
      setCurrentScreen(SCREENS.FIRST);
    }
  }, [isShown]);
  useEffect(() => {
    setShown(!training)
  }, [training]);

  const nextScreen = (() => {
    switch (currentScreen) {
      case SCREENS.FIRST:
        return SCREENS.SECOND;

      case SCREENS.SECOND:
        return SCREENS.THIRD;

      case SCREENS.THIRD:
        return SCREENS.FOURTH;

      case SCREENS.FOURTH:
        return SCREENS.FIFTH;

      case SCREENS.FIFTH:
      default:
        return null;
    }
  })();

  const previousScreen = (() => {
    switch (currentScreen) {
      case SCREENS.FIRST:
        return null;

      case SCREENS.FIFTH:
        return SCREENS.FOURTH;

      case SCREENS.FOURTH:
        return SCREENS.THIRD;

      case SCREENS.THIRD:
        return SCREENS.SECOND;

      case SCREENS.SECOND:
      default:
        return SCREENS.FIRST;
    }
  })();

  const controlClassesLeft = cx({
    ControlBetweenForms: true,
    ControlFormsDisabled: !previousScreen,
  });

  const controlClassesRight = cx({
    ControlBetweenForms: true,
    ControlFormsDisabled: !nextScreen,
  });

  const overlayClasses = cx({
    ComponentHelpOverlay: true,
    OverlayVisible: !!isShown,
    ComponentHelpOverlayBottomMenu: currentScreen === SCREENS.FOURTH
  });

  const startWorkPress = async () => {
    await setUserTrainingFlag(true);
    setShown(false);
  }

  const settings = {
    dots: true,
    infinite: false,
    arrows: true,
    className: 'slider-overlay',
    centerPadding: '20px',
    centerMode: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: current => {
      if (current === 0) {
        setCurrentScreen(SCREENS.FIRST);
      }
      if (current === 1) {
        setCurrentScreen(SCREENS.SECOND);
      }
      if (current === 2) {
        setCurrentScreen(SCREENS.THIRD);
      }
      if (current === 3) {
        setCurrentScreen(SCREENS.FOURTH);
      }
      if (current === 4) {
        setCurrentScreen(SCREENS.FIFTH);
      }
    },
  };

  return (
    <div>
      <HelpOverlayContext.Provider value={{
        helpScreen: currentScreen,
        SCREENS
      }}>
        {children({ isShown })}
      </HelpOverlayContext.Provider>
      <div className={overlayClasses}>
        <div
          className={styles.Overlay}
        />
        <div className={styles.VisibleBlock}>
          <div className={controlClassesLeft} onClick={() => previousScreen && setCurrentScreen(previousScreen)}>
            <ArrowLeftWhiteIcon />
          </div>

          {window.innerWidth >= 1080 && <div className={styles.Content}>
            <div className={screensContainer}>
              {currentScreen === SCREENS.FIRST && (
                <>
                  <img src={IntroFirst} alt="Intro" />
                  <h2>Получайте займы легко!</h2>
                  <p>Добро пожаловать в ваш личный кабинет сервиса Budgett. Здесь будут отображаться все данные о ваших займах и платежах. Пара стартовых подсказок поможет быстрее освоиться с управлением. Давайте начнём!</p>
                </>
              )}
              {currentScreen === SCREENS.SECOND && (
                <>
                  <h2>Настройте кредитную линию</h2>
                  <p>Мы оцениваем вашу платёжеспособность и устанавливаем для вас определённый лимит — от 30 000 до 300 000 рублей. Вы можете получить все деньги сразу, или по частям, кратным 30 000 рублей. При этом вы сами выбираете удобные для вас условия получения и возврата средств.</p>
                </>
              )}
              {currentScreen === SCREENS.THIRD && (
                <>
                  <h2>Если кредитная линия не одобрена</h2>
                  <p>Расчет кредитной линии занимает несколько минут.<br/>
                    Если вам не одобрили даже минимальный лимит, то, возможно, вы внесли недостаточно данных или допустили ошибку. Следуйте указаниям в боковом меню личного кабинета в разделе Поддержка. </p>
                </>
              )}
              {currentScreen === SCREENS.FOURTH && (
                <>
                  <h2>Вся информация под рукой</h2>
                  <p>Для переключения между разделами личного кабинета используйте панель навигации.  Каждый значок на панели быстрого доступа открывает меню со списком разделов Личного кабинета.</p>
                </>
              )}
              {currentScreen === SCREENS.FIFTH && (
                <>
                  <h2>Получите деньги уже сегодня</h2>
                  <p>Выберите один из возможных продуктов Budgett на удобных для вас условиях.</p>
                </>
              )}
              {currentScreen === SCREENS.FIFTH && (
                <Button
                  onClick={startWorkPress}
                  status={BUTTON_STATUSES.ACTIVED}
                >
                  Начать работу
                </Button>
              )}
            </div>
            <div className={styles.Points}>
              <div className={pointClassesFirst} />
              <div className={pointClassesSecond} />
              <div className={pointClassesThird} />
              <div className={pointClassesFourth} />
              <div className={pointClassesFifth} />
            </div>
          </div>}
          {window.innerWidth < 1080 &&

            <Slider {...settings}>
              <div className={styles.sliderItem}>
                <img src={IntroFirst} alt="Intro" />
                <h2>Получайте займы легко!</h2>
                <p>Добро пожаловать в ваш личный кабинет сервиса Budgett. Здесь будут отображаться все данные о ваших займах и платежах. Пара стартовых подсказок поможет быстрее освоиться с управлением. Давайте начнём!</p>
              </div>
              <div className={styles.sliderItem} >
                <h2>Настройте кредитную линию</h2>
                <p>Мы оцениваем вашу платёжеспособность и устанавливаем для вас определённый лимит — от 30 000 до 300 000 рублей. Вы можете получить все деньги сразу, или по частям, кратным 30 000 рублей. При этом вы сами выбираете удобные для вас условия получения и возврата средств.</p>
              </div>
              <div className={styles.sliderItem}>
                <h2>Если кредитная линия не одобрена</h2>
                <p>Расчет кредитной линии занимает несколько минут.<br/>
                  Если вам не одобрили даже минимальный лимит, то, возможно, вы внесли недостаточно данных или допустили ошибку. Следуйте указаниям в боковом меню личного кабинета в разделе Поддержка. </p>
              </div>
              <div className={styles.sliderItem}>
                <h2>Вся информация под рукой</h2>
                <p>Для переключения между разделами личного кабинета используйте панель навигации.  Каждый значок на панели быстрого доступа открывает меню со списком разделов Личного кабинета.</p>
              </div>
              <div className={styles.sliderItem}>
                <h2>Получите деньги уже сегодня</h2>
                <p>Выберите один из возможных продуктов Budgett на удобных для вас условиях.</p>
                <Button
                  onClick={startWorkPress}
                  status={BUTTON_STATUSES.ACTIVED}
                >
                  Начать работу
                </Button>
              </div>
            </Slider>}
          <div className={controlClassesRight} onClick={() => nextScreen && setCurrentScreen(nextScreen)}>
            <ArrowRightWhiteIcon />
          </div>
        </div>
      </div>
    </div>
  )

};

export default HelpOverlay;
