import React, { useEffect } from 'react';
import { cn } from '@bem-react/classname';
import {useHistory, useLocation} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PopupProvider } from '@src/components/Popup';
import { openLeftMenu, closeLeftMenu } from '@src/store/ducks/layout';
import { getContracts, getAccountStatus, getCreditLine, getUserFlags, refreshProfileState } from '@src/store/ducks/profile';
import { ACCOUNT_MODERATION_STATUSES } from '@src/constants';

import Header from './components/Header';
import LeftMenu from './components/LeftMenu';
import Subnav from '../MainLayout/components/Subnav';
import HelpOverlay from './components/HelpOverlay';
import './ProfileLayout.scss';

export const componentClassName = cn('Layout-Profile');

export const ProfileLayout = ({ children }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isLeftMenuOpened = useSelector(({ layout }) => layout.isLeftMenuOpened);
  const isMenuLocked = useSelector(({ layout }) => layout.isNavigationLocked);

  const contracts = useSelector(({ profile }) => profile.contracts);
  const accountModerationStatus = useSelector(({ profile }) => profile.accountModerationStatus);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const moveHandler = (e) => {
      if (e.clientX < 140 && !isLeftMenuOpened && !isMenuLocked) {
        dispatch(openLeftMenu());
      }
      else if (e.clientX >= 140 && isLeftMenuOpened) {
        dispatch(closeLeftMenu());
      }
    };

    window.addEventListener('mousemove', moveHandler);

    return () => window.removeEventListener('mousemove', moveHandler);
  }, [isLeftMenuOpened, isMenuLocked]);

  useEffect(() => {
    dispatch(refreshProfileState());

    const update = () => {
      dispatch(getUserFlags());
      dispatch(getContracts());
      dispatch(getCreditLine());
      dispatch(getAccountStatus());
    }
    update();
    const interval = setInterval(update, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const url = new URL(window.location)
    if(url.searchParams.get('status') === 'declined' && localStorage.getItem('direction') === 'OUTGOING'){
      return;
    }
    if(url.searchParams.get('status') === 'declined' && localStorage.getItem('direction') === 'INCOMING'){
      return;
    }
    if(url.searchParams.get('status') === 'approved' && localStorage.getItem('direction') === 'OUTGOING') {
      return;
    }
    if(url.searchParams.get('status') === 'approved' && localStorage.getItem('direction') === 'INCOMING') {
      return;
    }
    if (accountModerationStatus === ACCOUNT_MODERATION_STATUSES.REGISTERED) {
      history.push('/signup');
    } else {
      history.push('/profile/account-status');
    }
  }, [accountModerationStatus]);

  const widthWindow = () => window.innerWidth <= 1080;

  return (
    <PopupProvider>
      <HelpOverlay>
        {({ isShown, currentScreen }) => (
          <>
            {!widthWindow() && <Header helpScreen={currentScreen} />}
            <div className={componentClassName('Central-Block', { isLeftMenuOpened })}>
              <LeftMenu
                isFullView={isLeftMenuOpened}
                accountModerationStatus={accountModerationStatus}
                contracts={contracts}
                currentScreen={currentScreen}
              />
              <main className={componentClassName('Content-Block')}>
                {!isShown && children}
              </main>
            </div>
            {!widthWindow() && <Subnav/>}
          </>
        )}
      </HelpOverlay>
    </PopupProvider>
  )
};

export const getLayout = (page) => <ProfileLayout>{page}</ProfileLayout>;

export default ProfileLayout;
