import React from 'react';
import classnames from 'classnames';
import ScrollableAnchor from 'react-scrollable-anchor';
import OneIcon from '@src/assets/images/SignUpSteps/OneIcon.svg';
import TwoIcon from '@src/assets/images/SignUpSteps/TwoIcon.svg';
import ThreeIcon from '@src/assets/images/SignUpSteps/ThreeIcon.svg';
import FourIcon from '@src/assets/images/SignUpSteps/FourIcon.svg';
import FiveIcon from '@src/assets/images/SignUpSteps/FiveIcon.svg';
import SixIcon from '@src/assets/images/SignUpSteps/SixIcon.svg';

import './SignUpSteps.scss';
import SignUpStepsMobile from '../SignUpStepsMobile';

const SignUpSteps = () => (
  <ScrollableAnchor id="stepssignup">
    <div>
      <section className={classnames('component-sign-up-steps')}>
        <h2>Регистрация на CREDISTA</h2>
        <p className="component-sign-up-steps__description">Чтобы воспользоваться нашими продуктами, создайте личный кабинет. <br/>Это займёт всего несколько минут</p>

        <div className="component-sign-up-steps__content-block">
          <div className="component-sign-up-steps__content-block__item">
            <div className="component-sign-up-steps__content-block__item__header-block">
              <img className="component-sign-up-steps__content-block__item__header-block__image" src={OneIcon} alt="Icon" />
              <h3 className="component-sign-up-steps__content-block__item__header-block__title">Телефон <br/> и почта</h3>
            </div>
            <p className="component-sign-up-steps__content-block__item__text">Укажите адрес своей электронной почты и номер телефона. Они станут вашим ключом для входа в систему.</p>
          </div>
          <div className="component-sign-up-steps__content-block__item">
            <div className="component-sign-up-steps__content-block__item__header-block">
              <img className="component-sign-up-steps__content-block__item__header-block__image" src={TwoIcon} alt="Icon" />
              <h3 className="component-sign-up-steps__content-block__item__header-block__title">Паспортные данные</h3>
            </div>
            <p className="component-sign-up-steps__content-block__item__text">Введите свои паспортные данные. Они нужны, чтобы мы лучше узнали вас. Ваши данные будут под защитой. Мы никуда не передадим их без вашего согласия. </p>
          </div>
          <div className="component-sign-up-steps__content-block__item">
            <div className="component-sign-up-steps__content-block__item__header-block">
              <img className="component-sign-up-steps__content-block__item__header-block__image" src={ThreeIcon} alt="Icon" />
              <h3 className="component-sign-up-steps__content-block__item__header-block__title">Адрес проживания</h3>
            </div>
            <p className="component-sign-up-steps__content-block__item__text">Укажите адрес постоянной и временной регистрации. <br/>
              Это позволит предложить вам лучшие условия обслуживания в нашем сервисе.</p>
          </div>
          <div className="component-sign-up-steps__content-block__item">
            <div className="component-sign-up-steps__content-block__item__header-block">
              <img className="component-sign-up-steps__content-block__item__header-block__image" src={FourIcon} alt="Icon" />
              <h3 className="component-sign-up-steps__content-block__item__header-block__title">Селфи с паспортом</h3>
            </div>
            <p className="component-sign-up-steps__content-block__item__text">Напишите на листе бумаги дату, время и слово CREDISTA. Сделайте селфи с этим листком и паспортом. Так мы убедимся, что именно вы решили воспользоваться нашими услугами, а не мошенники от вашего имени.</p>
          </div>
          <div className="component-sign-up-steps__content-block__item">
            <div className="component-sign-up-steps__content-block__item__header-block">
              <img className="component-sign-up-steps__content-block__item__header-block__image" src={FiveIcon} alt="Icon" />
              <h3 className="component-sign-up-steps__content-block__item__header-block__title">Анализ <br/> бюджета</h3>
            </div>
            <p className="component-sign-up-steps__content-block__item__text">Укажите источники и уровень вашего дохода, а также данные о месте вашей работы. Расскажите, для каких целей вы бы хотели получить деньги.
              Нам важно, чтобы выплата займа была для вас комфортной и необременительной. </p>
          </div>
          <div className="component-sign-up-steps__content-block__item">
            <div className="component-sign-up-steps__content-block__item__header-block">
              <img className="component-sign-up-steps__content-block__item__header-block__image" src={SixIcon} alt="Icon" />
              <h3 className="component-sign-up-steps__content-block__item__header-block__title">Анализ транзакций</h3>
            </div>
            <p className="component-sign-up-steps__content-block__item__text">Если вы хотите сразу увеличить кредитный лимит, предоставьте данные для проверки ваших расходов.</p>
          </div>
        </div>
      </section>
      <section className={classnames('component-sign-up-steps', 'component-sign-up-steps_mobile')}>
        <SignUpStepsMobile />
      </section>
    </div>
  </ScrollableAnchor>
);

export default SignUpSteps;
