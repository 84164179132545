import React, { useEffect } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { configureAnchors } from 'react-scrollable-anchor'
import 'normalize.css';
import LandingPage from '@src/pages/LandingPage';
import '@src/App.scss';
import NotFoundPage from '@src/pages/404/NotFoundPage';

configureAnchors({ offset: -65 });

function App() {
  useEffect(() => {
    const jdivs = document.getElementsByTagName('jdiv');
    if (!(jdivs && jdivs[0])) {
      /* global loadScript */
      loadScript('//code.jivosite.com/widget/JfXF52JtUk');
    } else {
      jdivs[0].removeAttribute('style');
    }

    return () => {
      const jdivs = document.getElementsByTagName('jdiv');
      if (jdivs && jdivs[0]) {
        jdivs[0].setAttribute('style', 'display: none');
      }
    }
  }, []);

  return (
    <div className='app'>
      <BrowserRouter>
        <div>
          <Switch>
            <Route
              exact
              path="/"
              render={() => LandingPage.getLayout(<LandingPage />)}
            />
            <Route
              render={() => LandingPage.getLayout(<NotFoundPage />)}
            />
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
