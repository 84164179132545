import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ScrollableAnchor from 'react-scrollable-anchor';
import Modal from 'react-modal';
import cn from 'classnames';
import Button, { BUTTON_STATUSES } from '@components/Button';
import { ReactComponent as CloseIcon } from '@images/CloseIcon.svg';

import Range from './Range';
import RequirementsBar from './RequirementsBar';
import { RANGE_POINTS, POINT_LEVEL_MAP, REQUIREMENTS } from './constants';
import style from './CreditLine.module.scss';
import cookie from "js-cookie";

const CreditLine: FC = () => {
  const [requirementsLevel, setRequirementsLevel] = useState<number>(
      POINT_LEVEL_MAP[0]
  );
  const [isPopupOpened, setIsPopupOpened] = useState(false);

  // @ts-ignore
  const { isAuthenticated } = useSelector(({ auth }) => ({
    isAuthenticated: auth.isAuthenticated,
  }));

  const handleRangeChange = useCallback((pointIndex: number) => {
    setRequirementsLevel(POINT_LEVEL_MAP[pointIndex] - 1);
  }, []);

  const handleButtonClick = () => {
    window.ym(93509059,'reachGoal','get_limit')
    const paramsCookie = cookie.get('params')

    let params = ''

    if (paramsCookie) {
      params = paramsCookie + '&redirect=credista'
    }

    window.location.href =`https://budgett.ru/sign_up${params}`
  };

  const openPopup = () => {
    setIsPopupOpened(true);
  };

  const closePopup = () => {
    setIsPopupOpened(false);
  };

  useEffect(() => {
    if (isPopupOpened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isPopupOpened]);

  return (
      <ScrollableAnchor id="creditline">
        <section id="creditLine" className={style.section}>
          <div className={style.container}>
            <h2 className={style.title}>Настройте свою кредитную линию</h2>

            <div className={style.contentContainer}>
              <div className={style.column}>
                <div className={style.rangeSubtitleContainer}>
                  <h3 className={style.subtitle}>Желаемый лимит</h3>
                  <button
                      className={style.infoButton}
                      type="button"
                      onClick={openPopup}
                  >
                    ?
                  </button>
                </div>
                <p className={style.text}>
                  Мы оцениваем вашу платёжеспособность и устанавливаем для вас
                  определённый лимит — от 15 000 до 300 000 рублей. Свой лимит
                  можно постоянно увеличивать - двигайте ползунок, чтобы узнать
                  что для этого нужно делать.
                </p>
                <Range
                    className={style.range}
                    points={RANGE_POINTS}
                    initialPointIndex={0}
                    onPointChange={handleRangeChange}
                />
                <p className={style.text}>
                  Вы можете получить все деньги своего лимита сразу или по частям,
                  кратным 15 000. При этом вы сами выбираете удобные для вас
                  условия получения и возврата средств.
                </p>
              </div>

              <div className={style.column}>
                <div>
                  <h3 className={cn(style.subtitle, style.subtitleBottom)}>
                    Ваши действия для одобрения
                  </h3>
                </div>
                <RequirementsBar
                    items={REQUIREMENTS}
                    position={requirementsLevel}
                />
              </div>
            </div>
            {/* @ts-ignore */}
            <Button onClick={handleButtonClick} status={BUTTON_STATUSES.NEW}>
              Узнать свой лимит
            </Button>
          </div>
          <Modal
              isOpen={isPopupOpened}
              overlayClassName={style.popupOverlay}
              className={style.popup}
          >
            <h4 className={style.popupTitle}>Как можно расходовать свой лимит</h4>
            <button
                className={style.popupButton}
                type="button"
                onClick={closePopup}
            >
              <CloseIcon className={style.popupCloseIcon} />
            </button>
            <p className={style.popupText}>
              Вы можете получить все деньги своего лимита сразу, или по частям,
              кратным 15&#160;000 рублей. При этом вы сами выбираете удобные для
              вас условия получения и возврата средств.
            </p>
          </Modal>
        </section>
      </ScrollableAnchor>
  );
};

export default memo(CreditLine);
