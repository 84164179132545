import * as axios from 'axios';
import {
  AUTH_SERVICE_HOST,
  PROFILE_SERVICE_API_HOST,
  SALTEDGE_SERVICE_API_HOST,
  PAYMENTS_SERVICE_API_HOST,
  FILE_SERVICE_API_HOST,
  SMS_SERVICE_API_HOST,
  MAIL_SERVICE_API_HOST,
  REPORT_SERVICE_API_HOST,
  PRINT_SERVICE_API_HOST,
  CORE_SERVICE_API_HOST,
  KEYCLOAK_CLIENT,
  KEYCLOAK_REALM,
  DADATA_API_KEY,
  DADATA_SERVICE_API_HOST,
  CODE_EVENT_TYPE,
} from '@src/constants';

const dadataService = axios.create({
  baseURL: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Token ${DADATA_API_KEY}`,
  },
});

const budgettService = axios.create({
  baseURL: 'https://budgett.ru/mail-service/admin/request-call',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

const dadataCleanerService = axios.create({
  baseURL: DADATA_SERVICE_API_HOST,
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
  },
});

const authService = axios.create({
  baseURL: AUTH_SERVICE_HOST,
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

const saltedgeService = axios.create({
  baseURL: SALTEDGE_SERVICE_API_HOST,
  headers: {
    'content-type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});

const profileService = axios.create({
  baseURL: PROFILE_SERVICE_API_HOST,
  headers: {
    'content-type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});

const fileService = axios.create({
  baseURL: FILE_SERVICE_API_HOST,
  headers: {
    'content-type': 'multipart/form-data',
    'Cache-Control': 'no-cache',
  },
});

const smsService = axios.create({
  baseURL: SMS_SERVICE_API_HOST,
  headers: {
    'content-type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});

const mailService = axios.create({
  baseURL: MAIL_SERVICE_API_HOST,
  headers: {
    'content-type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});

const reportService = axios.create({
  baseURL: REPORT_SERVICE_API_HOST,
  headers: {
    'content-type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});

const printService = axios.create({
  baseURL: PRINT_SERVICE_API_HOST,
  headers: {
    'content-type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});

const coreService = axios.create({
  baseURL: CORE_SERVICE_API_HOST,
  headers: {
    'content-type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});

const paymentsService = axios.create({
  baseURL: PAYMENTS_SERVICE_API_HOST,
  headers: {
    'content-type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});

const staticFileService = axios.create({
  baseURL: '/',
  headers: {
    'content-type': 'multipart/form-data',
    'Cache-Control': 'no-cache',
  },
});

const request = async ({ service, method, url, data, params, responseType }) => {
  try {
    const { auth } = window.store.getState();

    const headers = auth?.accessToken ? {
      authorization: `Bearer ${auth.accessToken}`,
    } : {};

    let args = [];
    if (method === 'get') {
      args = [{ headers, responseType, params }];
    } else {
      args = [data, { headers, responseType }];
    }
    const result = await service[method](url, ...args);

    return { result, status: result.status };
  } catch (error) {
    return { error, status: error?.response?.status || error?.status };
  }
};

export const getStaticFile = async ({ url }, { responseType }) => await request({
  service: staticFileService,
  method: 'get',
  url,
  responseType,
});

export const getDaDataAddressHelp = async (data) => await request({
  service: dadataService,
  method: 'post',
  url: '/suggest/address',
  data,
});

export const getDaDataAddressClean = async (data) => await request({
  service: dadataCleanerService,
  method: 'post',
  url: '/clean/address',
  data,
});

export const sendMailCode = async ({ mail }) => await request({
  service: mailService,
  method: 'post',
  url: '/send/send_code',
  data: { mail },
});
export const sendRegistrationMailCode = async ({ mail }) => await request({
  service: mailService,
  method: 'post',
  url: '/send/send_code/registration',
  data: { mail },
});
export const checkMailCode = async ({ mail, code }) => await request({
  service: mailService,
  method: 'post',
  url: '/send/check_code',
  data: { mail, code },
});

export const sendHelpPhone = async ({ phone }) => await request({
  service: budgettService,
  method: 'post',
  data: { phone },
});

export const sendPhoneCode = async ({ phone, eventCode = CODE_EVENT_TYPE.DEFAULT }) => await request({
  service: smsService,
  method: 'post',
  url: '/send/send_code',
  data: { phone, eventCode },
});
export const sendRegistrationPhoneCode = async ({ phone, eventCode = CODE_EVENT_TYPE.DEFAULT }) => await request({
  service: smsService,
  method: 'post',
  url: '/send/send_code/registration',
  data: { phone, eventCode },
});
export const sendForgotPasswordPhoneCode = async ({ phone, eventCode = CODE_EVENT_TYPE.DEFAULT }) => await request({
  service: smsService,
  method: 'post',
  url: '/send/send_code/reset-password',
  data: { phone, eventCode },
});
export const checkPhoneCode = async ({ phone, code }) => await request({
  service: smsService,
  method: 'post',
  url: '/send/check_code',
  data: { phone, code },
});

export const getClient = async () => await request({
  service: profileService,
  method: 'get',
  url: '/client/get',
});

export const createClient = async ({
                                     mail,
                                     mailCode,
                                     password,
                                     phone,
                                     phoneCode,
                                   }) => await request({
  service: profileService,
  method: 'post',
  url: '/client/create',
  data: {
    mail,
    mailCode,
    password,
    phone,
    phoneCode,
  },
});
export const deleteClient = async () => await request({
  service: profileService,
  method: 'post',
  url: '/client/delete',
});

export const resetPassword = async ({ password, phone, phoneCode }) => await request({
  service: profileService,
  method: 'post',
  url: '/client/reset',
  data: {
    password,
    phone,
    phoneCode,
  },
});

export const changePhone = async ({ newPhone, oldPhone }) => await request({
  service: profileService,
  method: 'post',
  url: '/change/phone',
  data: {
    newPhone,
    oldPhone,
  },
});

export const changePassword = async ({ password }) => await request({
  service: profileService,
  method: 'post',
  url: '/change/password',
  data: {
    password,
  },
});

export const changeMail = async ({ mail, code }) => await request({
  service: profileService,
  method: 'post',
  url: '/change/mail/confirm',
  data: {
    mail,
    code,
  },
});

export const getAccountStatus = async () => await request({
  service: profileService,
  method: 'get',
  url: '/client/status',
});

export const login = async ({
                              username,
                              password,
                            }) => await request({
  service: authService,
  method: 'post',
  url: `/auth/realms/${KEYCLOAK_REALM}/protocol/openid-connect/token`,
  data: new URLSearchParams([
    ['client_id', KEYCLOAK_CLIENT],
    ['grant_type', 'password'],
    ['scope', 'openid'],
    ['username', username],
    ['password', password],
  ]),
});

export const logout = async () => {
  const { auth } = window.store.getState();

  return await request({
    service: authService,
    method: 'post',
    url: `/auth/realms/${KEYCLOAK_REALM}/protocol/openid-connect/logout`,
    data: new URLSearchParams([
      ['client_id', KEYCLOAK_CLIENT],
      ['grant_type', 'refresh_token'],
      ['refresh_token', auth.refreshToken],
      ['token', auth.accessToken],
    ]),
  });
};

export const refreshToken = async () => {
  const { auth } = window.store.getState();

  return await request({
    service: authService,
    method: 'post',
    url: `/auth/realms/${KEYCLOAK_REALM}/protocol/openid-connect/token`,
    data: new URLSearchParams([
      ['client_id', KEYCLOAK_CLIENT],
      ['grant_type', 'refresh_token'],
      ['refresh_token', auth.refreshToken],
    ]),
  });
};

export const getUserFlags = async () => await request({
  service: profileService,
  method: 'get',
  url: '/action/flags/currently',
});

export const setUserTrainingFlag = async (status) => await request({
  service: profileService,
  method: 'post',
  url: '/action/flags/set/training',
  data: {
    status,
  },
});

// добавляет паспортные данные человека
export const savePassportFullName = async ({
                                             firstName,
                                             patronymic,
                                             lastName,
                                             birthDate,
                                             gender,
                                           }) => await request({
  service: profileService,
  method: 'post',
  url: '/passport/save/fullname',
  data: {
    firstName,
    patronymic,
    lastName,
    birthDate,
    gender,
  },
});

// добавляет информацию о паспорте
export const savePassportDocument = async ({
                                             serialNumber,
                                             documentNumber,
                                             departmentCode,
                                             issuedBy,
                                             issuedDate,
                                             birthPlace,
                                           }) => await request({
  service: profileService,
  method: 'post',
  url: '/passport/save/document',
  data: {
    serialNumber,
    documentNumber,
    departmentCode,
    issuedBy,
    issuedDate,
    birthPlace,
  },
});

export const savePassport = async ({
                                     firstName,
                                     patronymic,
                                     lastName,
                                     birthDate,
                                     birthPlace,
                                     fileId,
                                     gender,
                                     serialNumber,
                                     departmentCode,
                                     documentNumber,
                                     issuedBy,
                                     issuedDate,
                                   }) => await request({
  service: profileService,
  method: 'post',
  url: '/passport/save',
  data: {
    firstName,
    patronymic,
    lastName,
    birthDate,
    birthPlace,
    fileId,
    gender,
    serialNumber,
    departmentCode,
    documentNumber,
    issuedBy,
    issuedDate,
  },
});

export const getPassport = async () => await request({
  service: profileService,
  method: 'get',
  url: '/passport/get',
});

// сохранение главной страницы паспорта
export const savePassportScanMain = async ({ fileId }) => await request({
  service: profileService,
  method: 'post',
  url: '/passport/save/scan/main',
  data: {
    fileId,
  },
});

// сохранение селфи с паспортом
export const savePassportScanSelfie = async ({ fileId }) => await request({
  service: profileService,
  method: 'post',
  url: '/passport/save/scan/selfie',
  data: {
    fileId,
  },
});

// сохранение страницы паспорта c регистрацией
export const savePassportScanRegistration = async ({ fileId }) => await request({
  service: profileService,
  method: 'post',
  url: '/passport/save/scan/registration',
  data: {
    fileId,
  },
});

// добавляет или обновляет существующие данные о работе
export const createPdnsEmployment = async ({
                                             employment,
                                             totalExpense,
                                             totalIncome,
                                             organizationName,
                                             organizationPhone,
                                             inn,
                                             additional,
                                           }) => await request({
  service: profileService,
  method: 'post',
  url: '/pdns/employment',
  data: {
    employment,
    totalExpense,
    totalIncome,
    organizationName,
    organizationPhone,
    inn,
    additional,
  },
});

// добавляет или обновляет существующие данные о контактах
export const createPdnsAdditionalContacts = async ({
                                                     messenger,
                                                     messengerPhone,
                                                     socialNetwork,
                                                     socialAccount,
                                                     whobelong,
                                                     contactPerson,
                                                     contactPersonPhone,
                                                   }) => await request({
  service: profileService,
  method: 'post',
  url: '/pdns/additional-contacts',
  data: {
    messenger,
    messengerPhone,
    socialNetwork,
    socialAccount,
    whobelong,
    contactPerson,
    contactPersonPhone,
  },
});


export const savePassportScan = async ({
                                         fullPageId,
                                         mainPageId,
                                         registrationPageId,
                                         selfieId,
                                         selfieRegistrationPageId,
                                       }) => await request({
  service: profileService,
  method: 'post',
  url: '/passport/save/scan',
  data: {
    fullPageId,
    mainPageId,
    registrationPageId,
    selfieId,
    selfieRegistrationPageId,
  },
});

export const savePassportScanNew = async ({
                                            mainPageId,
                                            selfieId,
                                          }) => await request({
  service: profileService,
  method: 'post',
  url: '/passport/save/scan/new',
  data: {
    mainPageId,
    selfieId,
  },
});

export const savePassportScanRegistrationNew = async ({
                                                        registrationPageId,
                                                        selfieRegistrationPageId,
                                                      }) => await request({
  service: profileService,
  method: 'post',
  url: '/passport/save/scan/registration/new',
  data: {
    registrationPageId,
    selfieRegistrationPageId,
  },
});

export const getPassportScan = async () => await request({
  service: profileService,
  method: 'get',
  url: '/passport/get/scan',
});
export const profileManagerLogout = async () => await request({
  service: profileService,
  method: 'get',
  url: '/sso/logout',
});

export const saveFile = async (data) => await request({
  service: fileService,
  method: 'post',
  url: '/files/save',
  data,
});

export const getFile = async ({ fileId }, { responseType }) => await request({
  service: fileService,
  method: 'get',
  url: `/files/${fileId}/file`,
  responseType,
});

export const saveAddresses = async (data) => await request({
  service: profileService,
  method: 'post',
  url: '/addresses/save',
  data,
});

export const getAddresses = async () => await request({
  service: profileService,
  method: 'get',
  url: '/addresses/get',
});

export const getCompletedSaltedgeList = async () => await request({
  service: saltedgeService,
  method: 'get',
  url: '/connection',
});


export const getSaltedgeConnectionUrl = async () => await request({
  service: saltedgeService,
  method: 'post',
  url: '/saltedge/connection',
});

export const postSaltedgeConnectionId = async ({ connectId }) => await request({
  service: saltedgeService,
  method: 'post',
  url: '/saltedge/transactions',
  data: {
    connectId,
  },
});

export const bankDocSave = async ({ requestDataList }) => await request({
  service: profileService,
  method: 'post',
  url: '/pdfs/bank-doc/save',
  data: {
    requestDataList,
  },
});

export const bankDoc = async (data) => await request({
  service: profileService,
  method: 'get',
  url: '/pdfs/bank-doc?bankDocType=BANK_STATEMENTS',
});

export const createPdns = async ({
                                   additional,
                                   additionalIncome,
                                   contactPerson,
                                   contactPersonPhone,
                                   employment,
                                   inn,
                                   messenger,
                                   messengerPhone,
                                   organizationName,
                                   organizationPhone,
                                   socialAccount,
                                   socialNetwork,
                                   totalExpense,
                                   totalIncome,
                                   whobelong,
                                 }) => await request({
  service: profileService,
  method: 'post',
  url: '/pdns',
  data: {
    additional,
    additionalIncome,
    contactPerson,
    contactPersonPhone,
    employment,
    inn,
    messenger,
    messengerPhone,
    organizationName,
    organizationPhone,
    socialAccount,
    socialNetwork,
    totalExpense,
    totalIncome,
    whobelong,
  },
});
export const updatePdns = async ({
                                   additional,
                                   additionalIncome,
                                   contactPerson,
                                   contactPersonPhone,
                                   employment,
                                   inn,
                                   messenger,
                                   messengerPhone,
                                   organizationName,
                                   organizationPhone,
                                   socialAccount,
                                   socialNetwork,
                                   totalExpense,
                                   totalIncome,
                                   whobelong,
                                 }) => await request({
  service: profileService,
  method: 'put',
  url: '/pdns',
  data: {
    additional,
    additionalIncome,
    contactPerson,
    contactPersonPhone,
    employment,
    inn,
    messenger,
    messengerPhone,
    organizationName,
    organizationPhone,
    socialAccount,
    socialNetwork,
    totalExpense,
    totalIncome,
    whobelong,
  },
});
export const getPdns = async () => await request({
  service: profileService,
  method: 'get',
  url: '/pdns',
});

export const createAdditionalDataFamily = async ({
                                                   familyStatus,
                                                   childrenNumber,
                                                   accommodations,
                                                   inn,
                                                   snills,
                                                   education,
                                                   otherLoans,
                                                   homePhone,
                                                   friendPhone,
                                                   volumeLoanObligations,
                                                 }) => await request({
  service: profileService,
  method: 'post',
  url: '/additional-data/family',
  data: {
    familyStatus,
    childrenNumber,
    accommodations,
    inn,
    snills,
    education,
    otherLoans,
    homePhone,
    friendPhone,
    volumeLoanObligations,
  },
});
export const updateAdditionalDataFamily = async ({
                                                   familyStatus,
                                                   childrenNumber,
                                                   accommodations,
                                                   inn,
                                                   snills,
                                                   education,
                                                   otherLoans,
                                                   homePhone,
                                                   friendPhone,
                                                   volumeLoanObligations,
                                                 }) => await request({
  service: profileService,
  method: 'put',
  url: '/additional-data/family',
  data: {
    familyStatus,
    childrenNumber,
    accommodations,
    inn,
    snills,
    education,
    otherLoans,
    homePhone,
    friendPhone,
    volumeLoanObligations,
  },
});
export const getAdditionalDataFamily = async () => await request({
  service: profileService,
  method: 'get',
  url: '/additional-data/family',
});

export const createAdditionalDataCommunication = async ({
                                                          telegram,
                                                          viber,
                                                          whatsapp,
                                                          url1,
                                                          url2,
                                                          url3,
                                                        }) => await request({
  service: profileService,
  method: 'post',
  url: '/additional-data/communication',
  data: {
    telegram,
    viber,
    whatsapp,
    url1,
    url2,
    url3,
  },
});
export const updateAdditionalDataCommunication = async ({
                                                          telegram,
                                                          viber,
                                                          whatsapp,
                                                          url1,
                                                          url2,
                                                          url3,
                                                        }) => await request({
  service: profileService,
  method: 'put',
  url: '/additional-data/communication',
  data: {
    telegram,
    viber,
    whatsapp,
    url1,
    url2,
    url3,
  },
});
export const getAdditionalDataCommunication = async () => await request({
  service: profileService,
  method: 'get',
  url: '/additional-data/communication',
});

export const createAdditionalDataTransport = async ({
                                                      transportType,
                                                      transportBrand,
                                                      yearIssue,
                                                      fileId,
                                                    }) => await request({
  service: profileService,
  method: 'post',
  url: '/additional-data/transport',
  data: {
    transportType,
    transportBrand,
    yearIssue,
    fileId,
  },
});
export const updateAdditionalDataTransport = async ({
                                                      transportType,
                                                      transportBrand,
                                                      yearIssue,
                                                      fileId,
                                                    }) => await request({
  service: profileService,
  method: 'put',
  url: '/additional-data/transport',
  data: {
    transportType,
    transportBrand,
    yearIssue,
    fileId,
  },
});
export const getAdditionalDataTransport = async () => await request({
  service: profileService,
  method: 'get',
  url: '/additional-data/transport',
});

export const createAdditionalDataTravel = async ({
                                                   countryNumber,
                                                   firstScan,
                                                   planTravel,
                                                   secondScan,
                                                 }) => await request({
  service: profileService,
  method: 'post',
  url: '/additional-data/travel',
  data: {
    countryNumber,
    firstScan,
    planTravel,
    secondScan,
  },
});
export const updateAdditionalDataTravel = async ({
                                                   countryNumber,
                                                   firstScan,
                                                   planTravel,
                                                   secondScan,
                                                 }) => await request({
  service: profileService,
  method: 'put',
  url: '/additional-data/travel',
  data: {
    countryNumber,
    firstScan,
    planTravel,
    secondScan,
  },
});
export const getAdditionalDataTravel = async () => await request({
  service: profileService,
  method: 'get',
  url: '/additional-data/travel',
});
export const requestToSaveAllPdfs = async ({ smsCode }) => await request({
  service: profileService,
  method: 'put',
  url: '/pdfs',
  data: {
    code: smsCode,
  },
});

export const getCurrentPayments = async () => await request({
  service: reportService,
  method: 'get',
  url: '/current-payments',
});
export const getClosedContracts = async () => await request({
  service: reportService,
  method: 'get',
  url: '/history-closed-contracts',
});
export const getLoadApplicationPayments = async ({ id }) => await request({
  service: reportService,
  method: 'get',
  url: '/details-payments-contract',
  params: {
    loanApplicationId: id,
    offset: 0,
    limit: 10000000,
  },
});
export const getSavedPaymentData = async () => await request({
  service: reportService,
  method: 'get',
  url: '/payment-data',
});

export const getCurrentContracts = async () => await request({
  service: coreService,
  method: 'get',
  url: '/loan/application/current-list',
});
export const getSolutionPlan = async ({ amount, firstPayDate, monthCount, type }) => await request({
  service: coreService,
  method: 'get',
  url: '/solution/plan/',
  params: { amount, firstPayDate, monthCount, type },
});
export const getSolution = async ({ type }) => await request({
  service: coreService,
  method: 'get',
  url: `/solution/${type}`,
});
export const getCreditLimit = async () => await request({
  service: coreService,
  method: 'get',
  url: '/limits/current',
});
export const getProduct = async () => await request({
  service: coreService,
  method: 'get',
  url: '/limits/current/products',
});
export const getLength = async () => await request({
  service: coreService,
  method: 'get',
  url: '/limits/current/length',
});
export const createLoadApplication = async ({
                                              amountOfContract,
                                              dateOfIssuance,
                                              loanTerm,
                                              paymentData,
                                              smsCode,
                                              typeOfProduct,
                                            }) => await request({
  service: coreService,
  method: 'post',
  url: '/loan/application',
  data: {
    amountOfContract,
    dateOfIssuance,
    loanTerm,
    paymentData,
    smsCode,
    typeOfProduct,
  },
});
export const getStatusLoadApplication = async ({ id }) => await request({
  service: coreService,
  method: 'post',
  url: '/loan/status',
  data: {
    loanApplicationId: id,
  },
});
export const getLoadApplication = async ({ id }) => await request({
  service: coreService,
  method: 'get',
  url: `/contract/${id}`,
});
export const checkEarlyPaymentForApplication = async ({ id, amount }) => await request({
  service: coreService,
  method: 'get',
  url: '/loan/check-payment',
  params: {
    amount,
    loanApplicationId: id,
  },
});
export const getEarlyPaymentRules = async ({ id, amount, paymentType }) => await request({
  service: coreService,
  method: 'post',
  url: '/payments-schedule/overpayment',
  data: {
    loanApplicationId: id,
    amount,
    paymentType,
  },
});

export const createContractTemplate = async ({
                                               amountOfContract,
                                               dateOfIssuance,
                                               loanTerm,
                                               typeOfProduct,
                                             }, { responseType }) => await request({
  service: printService,
  method: 'post',
  url: '/template/contract',
  responseType,
  data: {
    amountOfContract,
    dateOfIssuance,
    loanTerm,
    typeOfProduct,
  },
});


export const createCardAuth = async ({ cardCryptogramPacket, name, data }) => await request({
  service: paymentsService,
  method: 'post',
  url: '/cards/auth',
  data: {
    cardCryptogramPacket,
    name,
    data,
  },
});
export const getTransactionStatus = async ({ transactionId }) => await request({
  service: paymentsService,
  method: 'get',
  url: `/cards/auth/${transactionId}`,
});

export const checkAuth = async ({ id }) => await request({
  service: paymentsService,
  method: 'post',
  url: `/cards/check-auth?userId=${id}`,
});

export const sendCardPhoto = async ({ cardScanFileId, paymentDataId, paynetOrderId }) => await request({
  service: paymentsService,
  method: 'post',
  url: '/payment-data/save-card-scan',
  data: {
    cardScanFileId,
    paymentDataId,
    paynetOrderId,
  },
});

export const debitSpecifiedAmount = async ({
                                             amount,
                                             cardCryptogramPacket,
                                             isVisible,
                                             id,
                                             name,
                                             paymentType,
                                             data,
                                           }) => await request({
  service: paymentsService,
  method: 'post',
  url: '/debit/specified-amount',
  data: {
    amount,
    cardCryptogramPacket,
    isVisible,
    loanApplicationId: id,
    name,
    paymentType,
    data,
  },
});

export const debitCheckSpecifiedAmount = async ({
                                                  amount,
                                                  id,
                                                  paymentType,
                                                  prolongation,
  }) => await request({
  service: paymentsService,
  method: 'post',
  url: `/debit/check/specified-amount`,
  data: {
    amount,
    loanApplicationId: id,
    paymentType,
    prolongation,
  },
});
export const debitEarly = async ({ amount, paymentDataId, id, paymentType }) => await request({
  service: paymentsService,
  method: 'post',
  url: '/debit/early',
  data: {
    amount,
    loanApplication: id,
    paymentDataId,
    paymentType,
  },
});
export const deletePaymentData = async ({ paymentDataId }) => await request({
  service: paymentsService,
  method: 'post',
  url: `/payment-data/delete/${paymentDataId}`,
});

export const savePostBack = async ({ name, transactionId, webId }) => await request({
  service: profileService,
  method: 'post',
  url: `/cpa?name=${name}&transactionId=${transactionId}&webId=${webId}`,
});

export const getActualDocuments = async ({ clientId, fileType }) => await request({
  responseType: 'blob',
  service: printService,
  method: 'post',
  url: '/private/pdf ',
  data: {
    clientId,
    fileType,
  },
});

export const getActualTemplateDocuments = async ({ clientId, fileType }) => await request({
  responseType: 'blob',
  service: printService,
  method: 'post',
  url: '/template/pdf ',
  data: {
    clientId,
    fileType,
  },
});
