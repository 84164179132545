import React from 'react';
import classnames from 'classnames';
import ScrollableAnchor from 'react-scrollable-anchor';
import { SupportQuestions } from '@src/pages/Support';

import './Support.scss';
import SupportMobile from '../SupportMobile';

const Support = () => (
  <ScrollableAnchor id="stepsupport">
    <div>
      <section className={classnames('component-support')}>
        <h2>Часто задаваемые вопросы</h2>
        <SupportQuestions />
      </section>
      <section className={classnames('component-support', 'component-support_mobile')}>
        <SupportMobile />
      </section>
    </div>
  </ScrollableAnchor>
);

export default Support;
