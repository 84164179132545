import React, { useEffect, useState, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import classnames from 'classnames';
import lodash from 'lodash';
import { ACCOUNT_MODERATION_STATUSES } from '@src/constants';
import ContactsIcon from '@src/assets/images/ProfileLeftMenu/ContactsIcon.svg';
import IdIcon from '@src/assets/images/ProfileLeftMenu/IdIcon.svg';
import AddressesIcon from '@src/assets/images/ProfileLeftMenu/AddressesIcon.svg';
import SelfieIcon from '@src/assets/images/ProfileLeftMenu/SelfieIcon.svg';
import CalculatingIcon from '@src/assets/images/ProfileLeftMenu/CalculatingIcon.svg';
import SaltedgeIcon from '@src/assets/images/ProfileLeftMenu/SaltedgeIcon.svg';
import MyPaymentsIcon from '@src/assets/images/MyPaymentsIcon.svg';
import ContractDetailsIcon from '@src/assets/images/ProfileLeftMenu/ContractDetailsIcon.svg';
import ContractHistoryIcon from '@src/assets/images/ProfileLeftMenu/ContractHistoryIcon.svg';
import DocsIcon from '@src/assets/images/ProfileLeftMenu/DocsIcon.svg';
import AddContractIcon from '@src/components/Icons/AddContractIcon/AddContractIcon';
import MenuProfileIcon from '@src/components/Icons/MenuProfileIcon/MenuProfileIcon';
import MenuSettingsIcon from '@src/components/Icons/MenuSettingsIcon/MenuSettingsIcon';
import MenuPaymentsIcon from '@src/components/Icons/MenuPaymentsIcon/MenuPaymentsIcon';
import MenuSupportIcon from '@src/components/Icons/MenuSupportIcon/MenuSupportIcon';
import LockIcon from '@src/assets/images/ProfileLeftMenu/LockIcon.svg';
import DeleteAccountIcon from '@src/assets/images/ProfileLeftMenu/DeleteAccountIcon.svg';
import { ReactComponent as HelpArrowLeftFirst } from '@src/assets/images/HelpArrowLeftFirst.svg';
import { ReactComponent as HelpArrowLeftThird } from '@src/assets/images/HelpArrowLeftThird.svg';

import './LeftMenu.scss';
import { useSelector } from 'react-redux';

import { getClosedContracts, getUserFlags } from '../../../../api';
import { HelpOverlayContext } from '../HelpOverlay';
import Header from '../Header';

const widthWindow = () => window.innerWidth <= 1080


export const componentClassName = cn('Component-Profile-Layout-Left-Menu');

const isActiveLink = (location, link) => location.pathname === link;

const Link = ({ id, label, to, icon, help, disabled, forceActive, closeMenu }) => {
  const location = useLocation();
  const isActive = forceActive || isActiveLink(location, to);

  return (
    <div className={componentClassName('Link', { isActive, id })}>
      <NavLink to={to} className={classnames({
        'LinkContainer': true,
        'Disabled': disabled,
      })} onClick={(e) => {
        if (closeMenu) {
          closeMenu()
        }

        if (disabled) {
          e.preventDefault()
        }
      }}>
        <div className="Icon">
          {icon}
        </div>
        <div className="Label">
          {label}
        </div>
      </NavLink>
      {!!help && help}
    </div>
  )
}

const LinkSub = ({ id, label, to, icon, help, disabled, forceActive, closeMenu }) => {
  const location = useLocation();
  const isActive = forceActive || isActiveLink(location, to);

  return (
    <div className={componentClassName('Link', { isActive, id })}>
      <NavLink to={to} className={classnames({
        'LinkContainer': true,
        'Disabled': disabled,
      })} onClick={(e) => {
        if (closeMenu) {
          if(widthWindow()) {
            closeMenu()
          }
        }
        if (disabled) {
          e.preventDefault()
        }
      }}>
        <div className="Icon">
          <img src={icon} alt={label} />
        </div>
        <div className="Label">
          {label}
        </div>
      </NavLink>
      {!!help && help}
    </div>
  )
}

const Tree = ({ id, label, icon, help, links, disabled, openedTree, onClick, closeMenu }) => {
  const location = useLocation();
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (openedTree) {
      setIsOpened(id === openedTree);
    } else {
      setIsOpened(false);
    }
  }, [openedTree]);

  useEffect(() => {
    if (!openedTree) {
      const anyLinksIsActive = lodash.find(links, (link) => isActiveLink(location, link.to));
      if (window.innerWidth > 1080) {
        setIsOpened(!!anyLinksIsActive);
      }
    }
  }, [location]);

  const allCloseMenu = () => {
    if (closeMenu) {
      closeMenu()
    }
    setIsOpened(false)
  }

  return (
    <div className={componentClassName('Tree', { isOpened })}>
      <div className={classnames({
        'TreeHeaderContainer': true,
        'Disabled': disabled,
      })} onClick={() => !disabled && onClick(isOpened)}>
        <div className="Icon">
          {icon}
        </div>
        <div className="Label">
          {label}
        </div>
        {!disabled && <div className={classnames('Arrow', isOpened ? 'Arrow-Top' : 'Arrow-Bottom')} />}
      </div>
      {!!help && help}
      <div className={classnames({
        'Submenu': true,
        isOpened
      })}>
        <div className="submenu_container">
          <div className="submenu_title">
            {label}
          </div>
          {links.map(link => <LinkSub key={link.id} {...link} closeMenu={allCloseMenu}/>)}
        </div>
      </div>
    </div>
  )
}

const LeftMenu = ({ accountModerationStatus, isFullView, contracts, currentScreen }) => {
  const { helpScreen, SCREENS } = useContext(HelpOverlayContext);
  const isHelpFourthScreen = helpScreen === SCREENS.FOURTH;
  const isDemo1 =
    (accountModerationStatus === ACCOUNT_MODERATION_STATUSES.DATA_CHECKING && contracts.length === 0) ||
    accountModerationStatus === ACCOUNT_MODERATION_STATUSES.REGISTERED ||
    accountModerationStatus === ACCOUNT_MODERATION_STATUSES.FAILURE_LITTLE_DATA ||
    accountModerationStatus === ACCOUNT_MODERATION_STATUSES.DATA_MISMATCH;

  const isDemo2 =
    (accountModerationStatus === ACCOUNT_MODERATION_STATUSES.DATA_CHECKING && contracts.length !== 0) ||
    accountModerationStatus === ACCOUNT_MODERATION_STATUSES.LIMIT_REACHED;

  const [openedTree, setOpenedTree] = useState(null);
  const [closeContracts, setCloseContracts] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    (async () => {
      const { result } = await getUserFlags()
      window.ym(87515319, 'setUserID', result?.data?.clientId.toString());
    })()
  }, []);

  useEffect(() => {
    if (isHelpFourthScreen) {
      setOpenedTree('products');
    } else {
      setOpenedTree(null);
    }
  }, [isHelpFourthScreen])

  useEffect(() => {
    (async () => {
      const { result } = await getClosedContracts();

      if (result?.data) {
        setCloseContracts(result.data.rows.length > 0)
      }
    })();
  }, []);

  const contractList = contracts.filter((item) => item.status === 'ACCEPTED' || item.status === 'PROCESSED').map((contract) => ({
    id: `contract_details_${contract.id}`,
    label: `Детали Договора №${contract.contractNumber}`,
    icon: ContractDetailsIcon,
    to: `/profile/contract/${contract.id}`
  }))

  const closeMenu = () => {
    if (widthWindow()) {
      setTimeout(() => {
        setShow(false)
        setOpenedTree(null)
      }, 0)
    }
  }

  const today = new Date();
  const year = today.getFullYear();
  const isMenuLocked = useSelector(({ layout }) => layout.isNavigationLocked);

  return (
    <>
      <Header helpScreen={currentScreen} show={show} />
      <div className={componentClassName({ state: (isFullView || isHelpFourthScreen) ? 'open' : 'close', isHelpFourthScreen, isMenuLocked })}>
        {!widthWindow() &&
          <Link
            id="add_contract"
            label='Получить'
            icon={<AddContractIcon />}
            to="/profile/add-contract"
            disabled={isMenuLocked || isDemo1 || isDemo2}
          />
        }

        <Tree
          id="profile"
          label="Профиль"
          icon={<MenuProfileIcon />}
          openedTree={openedTree}
          onClick={(isOpened) => {
            setOpenedTree((openedTree === 'profile' || isOpened) ? false : 'profile')
            setShow(!isOpened)
          }}
          disabled={isMenuLocked}
          closeMenu={closeMenu}
          links={[{
          id: 'contacts',
          label: 'Телефон и почта',
          icon: ContactsIcon,
          to: '/profile/edit/contacts'
        }, {
          id: 'ids',
          label: 'Паспортные данные',
          icon: IdIcon,
          to: '/profile/edit/passport'
        }, {
          id: 'addresses',
          label: 'Адрес проживания',
          icon: AddressesIcon,
          to: '/profile/edit/addresses'
        }, {
          id: 'selfie',
          label: 'Селфи с паспортом',
          icon: SelfieIcon,
          to: '/profile/edit/selfie'
        }, {
          id: 'calculates',
          label: 'Анализ бюджета',
          icon: CalculatingIcon,
          to: '/profile/edit/pdn'
        }, {
          id: 'saltedge',
          label: 'Анализ транзакций',
          icon: SaltedgeIcon,
          to: '/profile/edit/saltedge'
        }]}
      />
        <Tree
          id="products"
          label={widthWindow() ? 'Платежи' : 'Текущие продукты'}
          icon={<MenuPaymentsIcon />}
          openedTree={openedTree}
          onClick={(isOpened) => {
            setOpenedTree((openedTree === 'products' || isOpened) ? false : 'products')
            setShow(!isOpened)
          }}
          closeMenu={closeMenu}
          disabled={isDemo1 || isMenuLocked || !(contracts.length > 0)}
          help={isHelpFourthScreen && (
          <p className={componentClassName('HelpMessage', { First: true })}>
            <HelpArrowLeftFirst />
            Здесь хранятся ваши личные данные.
          </p>
        )}
          links={isMenuLocked ? [] : [{
            id: 'my_payments',
            label: 'Мои платежи',
            icon: MyPaymentsIcon,
            to: '/profile/my-payments'
          },
          ...(isHelpFourthScreen ? [{
            id: `contract_details_0`,
            label: `Детали Договора №000001`,
            icon: ContractDetailsIcon,
            to: '/profile/contract/1',
            forceActive: true,
            help: isHelpFourthScreen && (
              <p className={componentClassName('HelpMessage', { Second: true })}>
                <HelpArrowLeftFirst />
                <span>Здесь находится вся информация о ваших текущих займах.</span>
              </p>
            ),
          }] : contractList )]}
      />

        {widthWindow() &&
        <Link
          id="add_contract"
          label='Получить'
          icon={<AddContractIcon />}
          to="/profile/add-contract"
          disabled={isDemo1 || isDemo2}
          closeMenu={closeMenu}
        />
      }
        <Tree
          id="settings"
          label="Настройки"
          icon={<MenuSettingsIcon />}
          openedTree={openedTree}
          onClick={(isOpened) => {
            setOpenedTree((openedTree === 'settings' || isOpened) ? false : 'settings');
            setShow(!isOpened)
          }}
          closeMenu={closeMenu}
          disabled={isMenuLocked}
          links={[{
          id: 'history_contracts',
          label: 'История договоров',
          icon: ContractHistoryIcon,
          to: '/profile/settings/history-contracts',
          disabled: !(contracts.length > 0 || closeContracts)
        }, {
          id: 'password_change',
          label: 'Изменение пароля',
          icon: LockIcon,
          to: '/profile/settings/password-change'
        },  {
          id: 'documents',
          label: 'Документы',
          icon: DocsIcon,
          to: '/profile/settings/documents'
        }, {
          id: 'delete_account',
          label: 'Удалить аккаунт',
          icon: DeleteAccountIcon,
          to: '/profile/settings/delete-account',
          disabled: !!contracts.length
        }]}
      />
        <Link
          id="support"
          label='Поддержка'
          icon={<MenuSupportIcon />}
          to="/profile/support"
          closeMenu={closeMenu}
          disabled={isMenuLocked}
      />

        {isHelpFourthScreen && (
        <p className={componentClassName('HelpMessage', { Third: true })}>
          <HelpArrowLeftThird />
          Если возникли вопросы или трудности, наши операторы помогут вам.
        </p>
      )}
      </div>
      <div className={componentClassName('Copyright')}>ООО МКК «Диджитал Мани», {year}</div>
    </>
  )
};

export default LeftMenu;
