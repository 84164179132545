import React, { useEffect, useState } from 'react';
import MenuIcon from '@src/assets/images/Subnav/Menu.svg';
import ParlourIcon from '@src/assets/images/Subnav/Parlour.svg';
import QuestionsIcon from '@src/assets/images/Subnav/Questions.svg';
import ReceiveIcon from '@src/assets/images/Subnav/Receive.svg';
import SupportIcon from '@src/assets/images/Subnav/Support.svg';
import Button from '@src/components/Button';

import { Context } from './context';
import Menu from './components/Menu';

import './Subnav.scss';
import cookie from "js-cookie";

const Subnav = () => {
  const [menuState, setMenuState] = useState(false);

  const hideMenu = () => {
    setMenuState(false);
  };

  const handleButtonClick = (link) => {
    const paramsCookie = cookie.get('params')

    let params = ''

    if (paramsCookie) {
      params = paramsCookie + '&redirect=credista'
    }

    window.location.href =`https://budgett.ru/${link}${params}`
  };

  return (
      <Context.Provider
          value={{
            hideMenu,
          }}
      >
        <nav className="subnav">
          {menuState && <Menu />}
          <ul className="subnav__tags">
            <li className="subnav__item">
              <Button
                  className="subnav__link"
                  onClick={() => setMenuState(!menuState)}
              >
                <div className="subnav__icon">
                  <img src={MenuIcon} alt="Menu" />
                </div>
                <p>Меню</p>
              </Button>
            </li>
            <li className="subnav__item">
              <Button
                  className="subnav__link"
                  onClick={() => handleButtonClick('login')}
              >
                <div className="subnav__icon">
                  <img src={ParlourIcon} alt="Parlour" />
                </div>
                <p>Кабинет</p>
              </Button>
            </li>
            <li className="subnav__item">
              <Button
                  className="subnav__link"
                  onClick={() => handleButtonClick('sign_up')}
              >
                <div className="subnav__icon">
                  <img src={ReceiveIcon} alt="Receive" />
                </div>
                <p>Получить</p>
              </Button>
            </li>
            <li className="subnav__item">
              <a className="subnav__link" href="/#stepsupport">
                <div className="subnav__icon">
                  <img src={QuestionsIcon} alt="Questions" />
                </div>
                <p>Вопросы</p>
              </a>
            </li>
            <li className="subnav__item">
              <Button
                  className="subnav__link"
                  onClick={() => {
                    window.scrollTo({
                      top: 10000000,
                      behavior: 'smooth',
                    });
                  }}
              >
                <div className="subnav__icon">
                  <img src={SupportIcon} alt="Support" />
                </div>
                <p>Помощь</p>
              </Button>
            </li>
          </ul>
        </nav>
      </Context.Provider>
  );
};

export default Subnav;